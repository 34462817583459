import moment from 'moment';
export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'center';
  name?: boolean;
  makeUpperCase?: boolean;
  format?: (value: number) => string;
  dateFormater?: (value: string) => string;
}

const dateConversionHandler = (date: string) => {
  return moment(date).format('DD-MM-YYYY hh:mm:ss');
};

const dateConversionHandlerByDateOnly = (date: string) => {
  return moment(date).format('DD-MM-YYYY hh:mm:ss');
};

const timeConversionHadler = (date: string) => {
  return moment(date).format('hh:mm');
};

export const columns: Column[] = [
  { id: 'uuid', label: 'UUID', minWidth: 100 },
  {
    id: 'name',
    label: 'Name',
    minWidth: 130,
    align: 'center',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'shortDescription',
    label: 'Short Description',
    minWidth: 130,
    align: 'center',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'locationName',
    label: 'Location',
    minWidth: 130,
    align: 'center',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'ticketProviderId',
    label: 'Ticket Provider',
    minWidth: 120,
    align: 'center',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'alias',
    label: 'Event Alias',
    minWidth: 120,
    align: 'center',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 100,
    align: 'center',
    makeUpperCase: true,
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'imageUrl',
    label: 'Image Url',
    minWidth: 80,
    align: 'center',
  },
  {
    id: 'websiteUrl',
    label: 'Website Url',
    minWidth: 80,
    align: 'center',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'weekday',
    label: 'Week Day',
    minWidth: 80,
    align: 'center',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'socialFacebook',
    label: 'Event Facebook Url',
    minWidth: 80,
    align: 'center',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'socialTwitter',
    label: 'Event Twitter Url',
    minWidth: 80,
    align: 'center',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'socialInstagram',
    label: 'Event Instagram Url',
    minWidth: 80,
    align: 'center',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'dateStart',
    label: 'Event Start Date',
    minWidth: 120,
    align: 'center',
    dateFormater: (value: string) => dateConversionHandlerByDateOnly(value),
  },
  {
    id: 'dateEnd',
    label: 'Event End Date',
    minWidth: 120,
    align: 'center',
    dateFormater: (value: string) => dateConversionHandlerByDateOnly(value),
  },
  {
    id: 'timezone',
    label: 'Event Timezone',
    minWidth: 80,
    align: 'center',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'timeStart',
    label: 'Event Start Time',
    minWidth: 120,
    align: 'center',
    dateFormater: (value: string) => timeConversionHadler(value),
  },
  {
    id: 'createdAt',
    label: 'Created At',
    minWidth: 150,
    align: 'center',
    dateFormater: (value: string) => dateConversionHandler(value),
  },
  {
    id: 'update',
    label: 'Actions',
    minWidth: 150,
    align: 'center',
  },
];
