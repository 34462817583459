import React, { FC, useState } from 'react';
import { Autocomplete, Modal, Box, TextField, Button, Grid, FormControl, InputLabel, InputAdornment, IconButton, FilledInput } from '@mui/material';
import { styled } from '@mui/material/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const ModalTitle = styled('h2')(({ theme }) => ({
  overflow: 'hidden',
  textAlign: 'center',
  marginTop: '0px',
}));

const ButtonDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '38rem',
  bgcolor: 'background.paper',
  p: 4,
  pt: 3,
  pb: 1,
  borderRadius: 2,
};

const statusOptions = [
  { name: 'In-Active', value: 'inactive' },
  { name: 'Active', value: 'active' },
];

const roleOptions = [
  { name: 'Admin', value: 'admin' },
  { name: 'Scanner', value: 'scanner' },
  { name: 'Promoter', value: 'promoter' },
];

interface CreateTicketProviderUserModalProps {
  title: string;
  openModal: boolean;
  closeModal: () => any;
  submitForm: () => any;
  inputValueHandler: (field: string, value: string | number, update?: boolean) => any;
  ticketProviders: any[];
  events: any[];
  selectedProviderId?: any;
  userObject?: any;
  updateUser?: () => void;
  shouldUpdateUser?: boolean;
  userStatus?: any;
}

const CreateTicketProviderUserModal: FC<CreateTicketProviderUserModalProps> = ({
  title,
  openModal,
  closeModal,
  submitForm,
  inputValueHandler,
  ticketProviders,
  selectedProviderId,
  userObject,
  updateUser,
  shouldUpdateUser,
  userStatus,
  events
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <Modal open={openModal} onClose={closeModal} aria-labelledby="modal-title" >
        <Box sx={style}>
          <ModalTitle id="modal-title">
            {title}
          </ModalTitle>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="login"
                name="login"
                label="Login"
                value={userObject?.login}
                fullWidth
                autoComplete="given-name"
                variant="standard"
                defaultValue={userObject?.name || ''}
                onChange={(e) => inputValueHandler('login', e.target.value)}
              />
            </Grid>
            {
              !shouldUpdateUser && (
                <Grid item xs={12} sm={6}>
                  <FormControl>
                    <InputLabel htmlFor="filled-adornment-password">
                      Password
                    </InputLabel>
                    <FilledInput
                      style={{marginTop: '-0.5rem'}}
                      id="filled-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={<InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>

                      </InputAdornment>}
                      onChange={(e) => inputValueHandler('password', e.target.value)}
                    />
                  </FormControl>
                </Grid>
              )
            }
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={roleOptions}
                defaultValue={roleOptions.find((role) => role.value === userObject?.role)}
                getOptionLabel={(option) => option.name}
                autoComplete
                includeInputInList
                onChange={(e: any, newValue: any) => {
                  inputValueHandler('role', newValue ? newValue?.value : '');
                }}
                renderInput={(params) => <TextField {...params} label="Role *" fullWidth variant="standard" />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disabled={userObject?.role === 'admin'}
                options={events}
                defaultValue={events?.find((event) => event.id === userObject?.eventId)}
                getOptionLabel={(option) => option.name}
                autoComplete
                includeInputInList
                onChange={(e: any, newValue: any) => {
                  inputValueHandler('eventId', newValue ? newValue?.id : '');
                }}
                renderInput={(params) => <TextField {...params} label="Event *" fullWidth variant="standard" />}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={ticketProviders}
                getOptionLabel={(option) => option.name}
                defaultValue={ticketProviders?.find((provider) => provider.id === userObject?.ticketProviderId)}
                autoComplete
                includeInputInList
                onChange={(e: any, newValue: any) => {
                  inputValueHandler('ticketProviderId', newValue ? newValue?.id : '');
                }}
                renderInput={(params) => <TextField {...params} label="Ticket Provider *" fullWidth variant="standard" />}
              />

            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={statusOptions}
                getOptionLabel={(option) => option.name}
                defaultValue={statusOptions?.find((status) => status.value === userObject?.status)}
                autoComplete
                includeInputInList
                onChange={(e: any, newValue: any) => {
                  inputValueHandler('status', newValue ? newValue?.value : '');
                }}
                renderInput={(params) => <TextField {...params} label="Status *" fullWidth variant="standard" />}
              />
            </Grid>
          </Grid>

          <ButtonDiv>
            <Button variant="contained" onClick={closeModal} sx={{ mt: 3, ml: 1 }} color="inherit">
              Close
            </Button>
            <Button
              variant="contained"
              onClick={shouldUpdateUser ? updateUser : submitForm}
              sx={{ mt: 3, ml: 1 }}
              color="primary"
            >
              {shouldUpdateUser ? 'Update' : 'Create'}
            </Button>
          </ButtonDiv>

        </Box>

      </Modal>
    </>

  )
};

export default CreateTicketProviderUserModal;
