/* eslint-disable @typescript-eslint/no-unused-expressions */
import network from '../../utils/network';

interface getListingParams {
  limit?: number;
  afterCursor?: string;
  beforeCursor?: string;
}
export interface cancelListinginterface {
  listingUuid: string;
  userUuid: string;
}

export const getListings = async ({ limit, afterCursor, beforeCursor }: getListingParams) => {
  let params: { [key: string]: any } = {};
  params.limit = limit;

  afterCursor ? (params.afterCursor = afterCursor) : '';
  beforeCursor ? (params.beforeCursor = beforeCursor) : '';

  const response = await network.get({
    path: `/listings`,
    options: {
      params,
    },
  });
  return response?.data;
};

export const cancelListing = async ({ listingUuid, userUuid }: cancelListinginterface) => {
  const response = await network.post(`/listings/cancel`, { listingUuid: listingUuid, userUuid });
  return response;
};
