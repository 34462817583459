import React, { FC, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Title from '../../components/Title/index';
import DataTable from '../../components/DataTable/index';
import { useMutation, useQuery } from 'react-query';
import {
  createEventService,
  getEvents,
  getTicketProviders,
  updateEventService,
  getTimeZones,
} from '../../services/app/events-service';
import { columns } from './table-columns';
import { errorHandler } from '../../utils/network/error-handler';
import { AxiosError } from 'axios';
import CreateEventModal from './CreateEventModal';
import { toast, ToastContainer } from 'react-toastify';
import dayjs from 'dayjs';

export interface EventsInterface {}

interface createEventValuesInterface {
  ticketProviderId: string | number;
  name: string[] | number[];
  description: string[] | number[];
  locationNames: string[] | number[];
  status: string | number;
  imageUrl: string | number;
  locales: string[];
  longDescriptions: string[] | number[];
  websiteUrl: string | number;
  dateStart: string | number;
  dateEnd: string | number;
  locationUrl: string | number;
  timeStart: string | number;
  weekday: string | number;
  socialTwitter: string | number;
  socialInstagram: string | number;
  socialFacebook: string | number;
  timezone: string | number;
  alias: string| number;
  locale?: string| number;
  uuid?: string;
}
interface createEventModalInterface {
  open: boolean;
  eventValues: createEventValuesInterface;
}

const PageContent = styled('div')(({ theme }) => ({
  marginBottom: '3rem',
}));

const Events: FC<EventsInterface> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [ticketProviders, setTicketProviders] = useState([]);
  const [ticketProvideFilterValue, setTicketProviderFilterValue] = useState('');
  const [editEnabled, setEditEnabled] = useState(false);
  const [events, setEvents] = useState({
    data: [],
    cursor: {
      afterCursor: '',
      beforeCursor: '',
    },
  });
  const [eventTimeZones, setEventTimeZones] = useState([]);

  const [openCreateModal, setOpenCreateModal] = useState<createEventModalInterface>({
    open: false,
    eventValues: {
      ticketProviderId: '',
      name: [],
      description: [],
      status: '',
      imageUrl: '',
      locales: ['en-US', 'pt-BR'],
      longDescriptions: [],
      locationNames: [],
      websiteUrl: '',
      dateStart: dayjs(new Date()).format('YYYY-MM-DD'),
      dateEnd: dayjs(new Date()).format('YYYY-MM-DD'),
      locationUrl: '',
      timeStart: '',
      weekday: '',
      socialTwitter: '',
      socialInstagram: '',
      socialFacebook: '',
      timezone: '',
      alias: ''
    },
  });

  const [createButtonClicked, setCreateButtonClicked] = useState(false)

  const clearState = () => {
    setCreateButtonClicked(false)
    setOpenCreateModal({
      open: false,
      eventValues: {
        ticketProviderId: '',
        alias: '',
        name: [],
        description: [],
        status: '',
        imageUrl: '',
        locales: ['en-US', 'pt-BR'],
        longDescriptions: [],
        locationNames: [],
        websiteUrl: '',
        dateStart: '',
        dateEnd: '',
        locationUrl: '',
        timeStart: '',
        weekday: '',
        socialTwitter: '',
        socialInstagram: '',
        socialFacebook: '',
        timezone: '',
        uuid: '',
      },
    });
  };

  useQuery(['ticket_provider'], () => getTicketProviders({ location }), {
    onSuccess: (data) => {
      let ticketProviders = [...data];
      ticketProviders.unshift({
        name: 'All',
        id: 0,
      });
      setTicketProviderFilterValue(ticketProviders[0]?.id);
      setTicketProviders(ticketProviders as any);
      getEventsQuery.refetch();
    },
    refetchOnWindowFocus: true,
  });

  const getEventsQuery = useQuery(
    [location.pathname.split('/').includes('validate-backend') ? 'event' : 'events', ticketProvideFilterValue],
    async () => getEvents({ limit: 10, ticketProviderId: ticketProvideFilterValue, location }),
    {
      onSuccess: (data) => {
        setEvents(data);
      },
      onError: (err: AxiosError) => errorHandler(err, navigate),
      refetchOnWindowFocus: true,
    },
  );

  useQuery('eventsTimeZone', async () => getTimeZones(), {
    onSuccess: (data) => {
      let arr: any = [];
      Object.keys(data).forEach((key) => {
        let obj: any = {};
        obj['name'] = key;
        obj['uuid'] = key;
        arr.push(obj);
      });
      arr.push({ uuid: 'UTC', name: 'UTC' });
      setEventTimeZones(arr);
    },
    onError: () => (err: AxiosError) => errorHandler(err, navigate),
    enabled: events?.data?.length ? true : false,
  });

  const createEventMutation = useMutation((data: any) => createEventService(data, location), {
    onSuccess: () => {
      clearState();
      getEventsQuery.refetch();
    },
    onError: (err: AxiosError) => errorHandler(err, navigate),
  });

  const updateEventMutation = useMutation((data: any) => updateEventService(data), {
    onSuccess: () => {
      clearState();
      setEditEnabled(false);
      getEventsQuery.refetch();
    },
    onError: (err: AxiosError) => errorHandler(err, navigate),
  });

  const tickProviderHandler = (ticketProviderId: string) => {
    setTicketProviderFilterValue(`${ticketProviderId}`);
  };

  const openModalHandler = () => {
    setOpenCreateModal({
      ...openCreateModal,
      open: true,
    });
  };
  const closeModalHandler = () => {
    setCreateButtonClicked(false)
    clearState();
    setEditEnabled(false);
  };

  const inputValueHandler = (field: string, value: string | number) => {
    const newValues = { ...openCreateModal.eventValues };

    switch (field) {
      case 'english_name':
        newValues.name[0] = value;
        break;
      case 'portuguese_name':
        newValues.name[1] = value;
        break;
      case 'english_description':
        newValues.description[0] = value;
        break;
      case 'portuguese_description':
        newValues.description[1] = value;
        break;
      case 'event_status':
        newValues.status = value;
        break;
      case 'event_alias':
        newValues.alias = value;
        break;
      case 'imageUrl':
        newValues.imageUrl = value;
        break;
      case 'english_long_description':
        newValues.longDescriptions[0] = value;
        break;
      case 'portuguese_long_description':
        newValues.longDescriptions[1] = value;
        break;
      case 'english_event_location':
        newValues.locationNames[0] = value;
        break;
      case 'portuguese_event_location':
        newValues.locationNames[1] = value;
        break;
      case 'websiteUrl':
        newValues.websiteUrl = value;
        break;
      case 'locationUrl':
        newValues.locationUrl = value;
        break;
      case 'event_dateStart':
        newValues.dateStart = value;
        break;
      case 'event_dateEnd':
        newValues.dateEnd = value;
        break;
      case 'time_start':
        newValues.timeStart = `${value}`;
        break;
      case 'week_days':
        newValues.weekday = value ? `${value}` : '';
        break;
      case 'event_twitter':
        newValues.socialTwitter = value;
        break;
      case 'event_instagram':
        newValues.socialInstagram = value;
        break;
      case 'event_facebook':
        newValues.socialFacebook = value;
        break;
      case 'event_timezone':
        newValues.timezone = value;
        break;
      case 'event_locale':
        newValues.locale = value;
        break;
      default:
        newValues.ticketProviderId = value;
        break;
    }

    setOpenCreateModal((prevState) => {
      return {
        ...prevState,
        eventValues: {
          ...prevState.eventValues,
          ...newValues,
        },
      };
    });
  };

  const mapData = () => {
    let data: { [key: string]: any } = {};
    let {
      ticketProviderId,
      name,
      description,
      imageUrl,
      locales,
      longDescriptions,
      websiteUrl,
      dateStart,
      dateEnd,
      locationUrl,
      timeStart,
      weekday,
      socialTwitter,
      socialInstagram,
      socialFacebook,
      status,
      locationNames,
      alias,
      uuid,
      timezone,
      locale
    } = openCreateModal.eventValues;

    if (timeStart?.toLocaleString()?.length === 8) {
      let splittedTimeStart = timeStart.toLocaleString().split(':');
      timeStart = `${splittedTimeStart[0]}` + ':' + `${splittedTimeStart[0]}`;
    }

    data.names = name;
    data.locales = locales;
    data.shortDescriptions = description;
    data.longDescriptions = longDescriptions;
    data.ticketProviderId = ticketProviderId;
    data.locationNames = locationNames;
    data.status = status;
    data.alias = alias;
    data.timezone = timezone

    imageUrl && (data.imageUrl = imageUrl);
    websiteUrl && (data.websiteUrl = websiteUrl);
    dateStart && (data.dateStart = dateStart);
    dateEnd && (data.dateEnd = dateEnd);
    locationUrl && (data.locationUrl = locationUrl);
    weekday && (data.weekday = weekday);
    timeStart && (data.timeStart = timeStart);
    socialTwitter && (data.socialTwitter = socialTwitter);
    socialInstagram && (data.socialInstagram = socialInstagram);
    socialFacebook && (data.socialFacebook = socialFacebook);
    locale && (data.locale = locale);
    uuid && (data.uuid = uuid);

    return data;
  };

  const createEvent = (update: boolean, uuid?: string) => {
    setCreateButtonClicked(true)
    const { name, description, ticketProviderId, longDescriptions, locationNames, status, alias, timezone } =
      openCreateModal.eventValues;
    if (
      name.length < 2 ||
      description.length < 2 ||
      longDescriptions.length < 2 ||
      !status ||
      locationNames.length < 2 ||
      !ticketProviderId ||
      !alias || !timezone
    ) {
      toast.error('Please Fill All the Fields', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return;
    }

    let data = mapData();

    if (!update) {
      createEventMutation.mutate(data);
    } else {
      updateEventMutation.mutate(data);
    }
  };

  const editRecordHandler = (record: any) => {
    const sortedNames = record?.translations?.sort((a: any, b: any) => (a.locale > b.locale ? 1 : -1));
    let firstElement: any = {
      name: '',
      description: '',
      longDescriptions: '',
      locationName: '',
    };
    let secondElement: any = {
      name: '',
      description: '',
      longDescriptions: '',
      locationName: '',
    };

    sortedNames?.forEach(({ entityAttribute, locale, text }: any) => {
      if (locale === 'en-US') {
        firstElement[entityAttribute] = text;
      } else {
        secondElement[entityAttribute] = text;
      }
    });

    setOpenCreateModal({
      open: false,
      eventValues: {
        ...openCreateModal.eventValues,
        ticketProviderId: record.ticketProviderId,
        name: [firstElement.name, secondElement.name],
        description: [firstElement.shortDescription, secondElement.shortDescription],
        imageUrl: record.imageUrl,
        locales: ['en-US', 'pt-BR'],
        longDescriptions: [firstElement.longDescription, secondElement.longDescription],
        locationNames: [firstElement.locationName, secondElement.locationName],
        websiteUrl: record.websiteUrl,
        status: record.status,
        dateStart: record.dateStart,
        dateEnd: record.dateEnd,
        locationUrl: record.locationUrl,
        timeStart: record.timeStart,
        weekday: record.weekday,
        socialTwitter: record.socialTwitter,
        socialInstagram: record.socialInstagram,
        socialFacebook: record.socialFacebook,
        uuid: record.uuid,
        timezone: record.timezone,
        alias: record.alias,
        locale: record?.locale
      },
    });
    setEditEnabled(true);
  };

  return (
    <>
      <PageContent>
        <Title title="Events" />
      </PageContent>
      <DataTable
        data={events?.data.length ? events : []}
        columns={columns}
        ticketProviders={ticketProviders}
        tickProviderHandler={tickProviderHandler}
        ticketProvideFilterValue={ticketProvideFilterValue}
        buttonText={location.pathname.split('/')[1] === 'validate-web-backend' ? '' : 'Create'}
        createClickHandler={openModalHandler}
        editRecordHandler={editRecordHandler}
      />

      <CreateEventModal
        title="Create Event"
        closeModal={closeModalHandler}
        openModal={openCreateModal.open || editEnabled}
        submitForm={createEvent}
        inputValueHandler={inputValueHandler}
        ticketProviders={ticketProviders}
        modalFieldValues={openCreateModal.eventValues}
        isEditable={editEnabled}
        eventsTimeZones={eventTimeZones}
        createButtonClicked={createButtonClicked}
      />
      <ToastContainer />
    </>
  );
};

export default Events;
