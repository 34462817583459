/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import { Box, Button, Grid, Modal, TextField, Autocomplete, Tabs, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';



const ModalTitle = styled('h2')(({ theme }) => ({
  overflow: 'hidden',
  textAlign: 'center',
  marginTop: '0px',
}));

const ButtonDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '38rem',
  height: '38rem',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  p: 4,
  pt: 3,
  pb: 1,
  borderRadius: 2,
};

interface optionType {
  id: number;
  name: string;
}

interface CreateEventModalProps {
  title: string;
  openModal: boolean;
  closeModal: () => any;
  submitForm: (update: boolean, uuid?: string) => any;
  inputValueHandler: (field: string, value: string | number) => any;
  ticketProviders: any;
  modalFieldValues: any;
  isEditable: boolean;
  editRecordInfo?: any;
  eventsTimeZones: any[];
  createButtonClicked: boolean;
}

const CreateEventModal: React.FC<CreateEventModalProps> = ({
  title,
  openModal,
  closeModal,
  submitForm,
  inputValueHandler,
  ticketProviders,
  modalFieldValues,
  isEditable,
  eventsTimeZones,
  createButtonClicked
}) => {
  const [ tabValue, setTabValue ] = useState(0);

  const [dateStart, setDateStart] = useState<Dayjs | null>(dayjs(new Date()));
  const [dateEnd, setDateEnd] = useState<Dayjs | null>(dayjs(new Date()));
  const [timePickerValue, setTimePickerValue] = React.useState<Dayjs | null>(null);
  const [weekDays] = useState(['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'])
  const [eventStatus] = useState([
    {name: 'Active', value: 'active'},
    {name: 'Inactive', value: 'inactive'},
    {name: 'Upcoming', value: 'upcoming'},
    {name: 'Sold Out', value: 'sold_out'},
    {name: 'Deleted', value: 'deleted'}
  ]);

  const [eventLocale] = useState([
    {
      name: 'en-US',
      value: 'en-US'
    },
    {
      name: 'pt-BR',
      value: 'pt-BR'
    },
  ])

  return (
    <>
      <Modal open={openModal} onClose={closeModal} aria-labelledby="modal-title">
        <Box sx={style}>
          <ModalTitle id="modal-title">{isEditable ? 'Update Event' : title}</ModalTitle>

          <Tabs value={tabValue} onChange={(e: any, newValue: number) => setTabValue(newValue)}>
            <Tab label="English"/>
            <Tab label="Portugiese"/>
          </Tabs>

          {
            tabValue === 0 && (
              <Grid container spacing={3} sx={{pt: 3}}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="eventName"
                    name="eventName"
                    label="Event Name"
                    value={modalFieldValues?.name[0]}
                    error={createButtonClicked && (modalFieldValues?.name.length === 0 || modalFieldValues?.name[0] === '') ? true : false}
                    helperText={createButtonClicked && (modalFieldValues.name.length === 0 || modalFieldValues?.name[0] === '') ? 'Event English name is required' : ''}
                    fullWidth
                    autoComplete="given-name"
                    variant="standard"
                    onChange={(e) => inputValueHandler('english_name', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="eventDescription"
                    name="eventDescription"
                    label="Event Short Description"
                    fullWidth
                    error={createButtonClicked && (modalFieldValues?.description.length === 0 || modalFieldValues?.description[0] === '') ? true : false}
                    helperText={createButtonClicked && (modalFieldValues.description.length === 0 || modalFieldValues?.description[0] === '') ? 'Event English Short Description is required' : ''}
                    value={modalFieldValues?.description[0]}
                    autoComplete="given-name"
                    variant="standard"
                    onChange={(e) => inputValueHandler('english_description', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="eventDescription"
                    name="eventDescription"
                    label="Event Long Description"
                    fullWidth
                    error={createButtonClicked && (modalFieldValues?.longDescriptions.length === 0 || modalFieldValues?.longDescriptions[0] === '') ? true : false}
                    helperText={createButtonClicked && (modalFieldValues.longDescriptions.length === 0 || modalFieldValues?.longDescriptions[0] === '') ? 'Event English Long Description is required' : ''}
                    value={modalFieldValues?.longDescriptions[0]}
                    autoComplete="given-name"
                    variant="standard"
                    onChange={(e) => inputValueHandler('english_long_description', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="eventLocation"
                    name="eventLocation"
                    label="Event Location"
                    fullWidth
                    error={createButtonClicked && (modalFieldValues?.locationNames.length === 0 || modalFieldValues?.locationNames[0] === '') ? true : false}
                    helperText={createButtonClicked && (modalFieldValues.locationNames.length === 0 || modalFieldValues?.locationNames[0] === '') ? 'Event English Location is required' : ''}
                    value={modalFieldValues?.locationNames[0]}
                    autoComplete="given-name"
                    variant="standard"
                    onChange={(e) => inputValueHandler('english_event_location', e.target.value)}
                  />
                </Grid>
              </Grid>
            )
          }
          {
            tabValue === 1 && (
              <Grid container spacing={3} sx={{pt: 3}}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="eventName"
                    name="eventName"
                    label="Nome do evento"
                    fullWidth
                    error={createButtonClicked && (modalFieldValues?.name.length === 0 || modalFieldValues?.name[1] === '') ? true : false}
                    helperText={createButtonClicked && (modalFieldValues.name.length === 0 || modalFieldValues?.name[1] === '') ? 'Event Portugiese Name is required' : ''}
                    value={modalFieldValues?.name[1]}
                    autoComplete="given-name"
                    variant="standard"
                    onChange={(e) => inputValueHandler('portuguese_name', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="eventDescription"
                    name="eventDescription"
                    label="Descrição do Evento"
                    fullWidth
                    error={createButtonClicked && (modalFieldValues?.description.length === 0 || modalFieldValues?.description[1] === '') ? true : false}
                    helperText={createButtonClicked && (modalFieldValues.description.length === 0 || modalFieldValues?.description[1] === '') ? 'Event Portugiese Short Description is required' : ''}
                    value={modalFieldValues?.description[1]}
                    autoComplete="given-name"
                    variant="standard"
                    onChange={(e) => inputValueHandler('portuguese_description', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="eventDescription"
                    name="eventDescription"
                    label="Descrição longa do evento"
                    fullWidth
                    error={createButtonClicked && (modalFieldValues?.longDescriptions.length === 0 || modalFieldValues?.longDescriptions[1] === '') ? true : false}
                    helperText={createButtonClicked && (modalFieldValues.longDescriptions.length === 0 || modalFieldValues?.longDescriptions[1] === '') ? 'Event Portugiese Long Description is required' : ''}
                    value={modalFieldValues?.longDescriptions[1]}
                    autoComplete="given-name"
                    variant="standard"
                    onChange={(e) => inputValueHandler('portuguese_long_description', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="eventLocation"
                    name="eventLocation"
                    label="Local do evento"
                    fullWidth
                    error={createButtonClicked && (modalFieldValues?.locationNames.length === 0 || modalFieldValues?.locationNames[1] === '') ? true : false}
                    helperText={createButtonClicked && (modalFieldValues.locationNames.length === 0 || modalFieldValues?.locationNames[1] === '') ? 'Event Portugiese Location is required' : ''}
                    value={modalFieldValues?.locationNames[1]}
                    autoComplete="given-name"
                    variant="standard"
                    onChange={(e) => inputValueHandler('portuguese_event_location', e.target.value)}
                  />
                </Grid>
              </Grid>
            )
          }
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={ticketProviders.filter((provider: any) => provider.id)}
                getOptionLabel={(option: optionType) => option.name}
                autoComplete
                includeInputInList
                defaultValue={ticketProviders?.find((provider: any) => provider.id === modalFieldValues?.ticketProviderId)}
                onChange={(e: any, newValue: optionType | null) => {
                  inputValueHandler('ticketProviderId', newValue ? newValue?.id : 0);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={createButtonClicked && modalFieldValues.ticketProviderId === '' ? true : false}
                    helperText={createButtonClicked && modalFieldValues.ticketProviderId === '' ? 'Ticket Provider is required' : ''}
                    label="Ticket Provider *"
                    fullWidth
                    variant="standard"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={eventStatus.filter((day: any) => day.name)}
                getOptionLabel={(option: any) => option.name}
                autoComplete
                defaultValue={eventStatus?.find((status: any) => status.value === modalFieldValues?.status)}
                includeInputInList
                onChange={(e: any, newValue: any | null) => {
                  inputValueHandler('event_status', newValue?.value ? newValue?.value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={createButtonClicked && modalFieldValues.status === '' ? true : false}
                    helperText={createButtonClicked && modalFieldValues.status === '' ? 'Event Status is required' : ''}
                    label="Event Status*"
                    fullWidth
                    variant="standard"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={eventsTimeZones.filter((day: any) => day.name)}
                getOptionLabel={(option: any) => option.name}
                autoComplete
                defaultValue={eventsTimeZones?.find((status: any) => status.name === modalFieldValues?.timezone)}
                includeInputInList
                onChange={(e: any, newValue: any | null) => {
                  inputValueHandler('event_timezone', newValue?.uuid ? newValue?.uuid : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={createButtonClicked && modalFieldValues.timezone === '' ? true : false}
                    helperText={createButtonClicked && modalFieldValues.timezone === '' ? 'Event TimeZone is required' : ''}
                    label="Event Timezone*"
                    fullWidth
                    variant="standard"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="eventAlias"
                name="eventAlias"
                label="Alias Name*"
                error={createButtonClicked && modalFieldValues.alias === '' ? true : false}
                helperText={createButtonClicked && modalFieldValues.alias === '' ? 'Event Alias is required' : ''}
                fullWidth
                defaultValue={modalFieldValues?.alias}
                autoComplete="given-image-url"
                variant="standard"
                onChange={(e) => inputValueHandler('event_alias', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={eventLocale.filter((day: any) => day.name)}
                getOptionLabel={(option: any) => option.name}
                autoComplete
                defaultValue={eventLocale?.find((status: any) => status.name === modalFieldValues?.locale)}
                includeInputInList
                onChange={(e: any, newValue: any | null) => {
                  inputValueHandler('event_locale', newValue?.value ? newValue?.value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Event Locale"
                    fullWidth
                    variant="standard"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="imageUrl"
                name="imageUrl"
                label="Image URL"
                fullWidth
                defaultValue={modalFieldValues?.imageUrl}
                autoComplete="given-image-url"
                variant="standard"
                onChange={(e) => inputValueHandler('imageUrl', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="imageUrl"
                name="imageUrl"
                label="Website URL"
                fullWidth
                defaultValue={modalFieldValues?.websiteUrl}
                autoComplete="given-image-url"
                variant="standard"
                onChange={(e) => inputValueHandler('websiteUrl', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="imageUrl"
                name="imageUrl"
                label="Location URL"
                defaultValue={modalFieldValues?.locationUrl}
                fullWidth
                autoComplete="given-image-url"
                variant="standard"
                onChange={(e) => inputValueHandler('locationUrl', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Event Start Date"
                  value={modalFieldValues?.dateStart ? modalFieldValues?.dateStart : null}
                  onChange={(newValue: Dayjs | null) => {
                    setDateStart(newValue);
                    inputValueHandler('event_dateStart', newValue !== null ? newValue.format('YYYY-MM-DD') : '');
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Event End Date"
                  value={modalFieldValues?.dateEnd ? modalFieldValues?.dateEnd : null}
                  onChange={(newValue: Dayjs | null) => {
                    setDateEnd(newValue);
                    inputValueHandler('event_dateEnd', newValue !== null ? newValue.format('YYYY-MM-DD') : '');
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopTimePicker
                  label="Event Time"
                  value={timePickerValue}
                  onChange={(newValue: Dayjs | null) => {
                    setTimePickerValue(newValue);
                    inputValueHandler('time_start', newValue !== null ? newValue.format('hh:mm') : '');
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={weekDays.filter((day: any) => day)}
                getOptionLabel={(option: any) => option}
                autoComplete
                defaultValue={modalFieldValues?.weekday}
                includeInputInList
                onChange={(e: any, newValue: any | null) => {
                  inputValueHandler('week_days', newValue ? newValue : null);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="WeekDays" fullWidth variant="standard" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="imageUrl"
                name="imageUrl"
                label="Event Twitter Url"
                defaultValue={modalFieldValues?.socialTwitter}
                fullWidth
                autoComplete="given-image-url"
                variant="standard"
                onChange={(e) => inputValueHandler('event_twitter', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField
                id="imageUrl"
                name="imageUrl"
                label="Event Instagram URL"
                fullWidth
                defaultValue={modalFieldValues?.socialInstagram}
                autoComplete="given-image-url"
                variant="standard"
                onChange={(e) => inputValueHandler('event_instagram', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="imageUrl"
                name="imageUrl"
                label="Event Facebook URL"
                fullWidth
                defaultValue={modalFieldValues?.socialFacebook}
                autoComplete="given-image-url"
                variant="standard"
                onChange={(e) => inputValueHandler('event_facebook', e.target.value)}
              />
            </Grid>
          </Grid>

          <ButtonDiv>
            <Button variant="contained" onClick={closeModal} sx={{ mt: 3, ml: 1 }} color="inherit">
              Close
            </Button>
            {
              isEditable ? (
                <Button variant="contained" onClick={() => submitForm(true)} sx={{ mt: 3, ml: 1 }} color="primary">
                  Update
                </Button>
              ): (
                <Button variant="contained" onClick={() => submitForm(false)} sx={{ mt: 3, ml: 1 }} color="primary">
                  Create
                </Button>
              )
            }
          </ButtonDiv>
        </Box>
      </Modal>
    </>
  );
};

export default CreateEventModal;
