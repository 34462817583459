import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Title from '../../components/Title/index';
import DataTable from '../../components/DataTable/index';
import { columns } from './table-column'
import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { errorHandler } from '../../utils/network/error-handler';
import { getMessages, resendAllMessages, resendOneMessages } from '../../services/app/messages-service';
import ConfirmationModal from '../../components/ConfirmationModal/index'
import { toast, ToastContainer } from 'react-toastify';
import { debounce } from 'lodash';

const PageContent = styled('div')(({ theme }) => ({
  marginBottom: '3rem',
}));

const MessagePage = () => {

  const navigate = useNavigate();
  const [messages, setMessages] = useState({
    data: [],
    cursor: {
      afterCursor: '',
      beforeCursor: '',
    },
  });

  const [tableSize, setTableSize] = useState({
    default: 10,
    list: [5, 10, 25],
  });

  const [currentCursor, setCurrentCursor] = useState({
    name: '',
    value: '',
  });

  const [messagesSearchedValues, setMessagesSearchedValues] = useState<any>({
    userPhoneNumber: '',
    userEmail: '',
  })

  const [ openConfirmationModal, setOpenConfirmationModal ] = useState(false)
  const [ resendMultipleMessages, setResendMultipleMessages ] = useState(false)
  const [ selectedResendMessageUuid, setSelectedResendMessageUuid ] = useState(false)
  const [ showLoading, setShowLoading ] = useState(false)

  const pageSizeHandler = (pageSize: number) => {
    setTableSize({
      ...tableSize,
      default: pageSize,
    });
    getMessagesQuery.refetch();
  };

  const changePageHandler = (changePage: string) => {
    const { cursor } = messages || {};
    const { afterCursor, beforeCursor } = cursor || {};
    if (changePage === 'go_back' && beforeCursor !== '') {
      setCurrentCursor({
        name: 'previous',
        value: beforeCursor,
      });
    } else {
      if (afterCursor !== '') {
        setCurrentCursor({
          name: 'next',
          value: afterCursor,
        });
      }
    }
    getMessagesQuery.refetch();
  };

  const getMessagesQuery = useQuery(
    ['messages', tableSize.default, messagesSearchedValues, currentCursor.value],
    async () => getMessages({ 
      limit: tableSize.default,
      messagesSearchedParams: messagesSearchedValues,
      afterCursor: currentCursor.name === 'next' ? currentCursor.value : '',
      beforeCursor: currentCursor.name === 'previuous' ? currentCursor.value : '', }),
    {
      onSuccess: (data) => {
        setMessages(data);
      },
      onError: (err: AxiosError) => errorHandler(err, navigate),
      refetchOnWindowFocus: true,
    },
  );

  const showNotification = (message: string ) => {
    toast.success(`${message}`, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
    return 
  }
  
  const resendAllMessagesQuery = useMutation((data: any) => resendAllMessages(data), {
    onSuccess: (data) => {
      showNotification('All messages Re-sended Successfully');
      setShowLoading(false)
      setOpenConfirmationModal(!openConfirmationModal)
      setResendMultipleMessages(!resendMultipleMessages)
      getMessagesQuery.refetch()
    },
    onError: (err: AxiosError) => errorHandler(err, navigate),
  })

  const resendOneMessagesQuery = useMutation((data: any) => resendOneMessages(data), {
    onSuccess: (data) => {
      if(data?.id){
        showNotification('Message Re-sended Successfully');
        setShowLoading(false)
        setOpenConfirmationModal(!openConfirmationModal)
        setSelectedResendMessageUuid(false)
        getMessagesQuery.refetch()
      }
    },
    onError: (err: AxiosError) => errorHandler(err, navigate),
  })

  const messagesSearchHandler = debounce((feild: string, value: any) => {
    switch(feild){
      case 'user_phoneNumber':
        setMessagesSearchedValues({ ...messagesSearchedValues, userPhoneNumber: value}) 
        break;
        case 'user_email': 
        
        setMessagesSearchedValues({ ...messagesSearchedValues, userEmail: value}) 
        break;
      default:
        break;
    }
  }, 1000)

  const messageClickHandler = (row: any) => {
    navigate(`/messages/${row?.uuid}`)
  }

  const resendAllMessagesHandler = () => {
    setOpenConfirmationModal(!openConfirmationModal)
    setResendMultipleMessages(!resendMultipleMessages)
  }

  const resendOneMessage = (row:any) => {
    setOpenConfirmationModal(!openConfirmationModal)
    setSelectedResendMessageUuid(row?.uuid)
  }

  const closeConfirmationModal = () => {
    setOpenConfirmationModal(!openConfirmationModal)
  }

  const reSendMessagesHandler = () => {
    if(resendMultipleMessages){
      resendAllMessagesQuery.mutate({ resendAll: true })
      return ;
    }
    setShowLoading(true);
    resendOneMessagesQuery.mutate({uuid: selectedResendMessageUuid})
  }

  return (
    <>
      <PageContent>
        <Title title="Messages" />
      </PageContent>
      <DataTable
        data={messages?.data.length ? messages : []}
        columns={columns}
        pageSizeChangeHandler={(pageSize: number) => pageSizeHandler(pageSize)}
        changePageHandler={changePageHandler}
        tableSize={tableSize}
        inputChangeHandler={messagesSearchHandler}
        showUserPhoneFilter={true}
        showUserEmailFilter={true}
        rowClickHandler={messageClickHandler}
        buttonText="Resend all errors"
        createClickHandler={resendAllMessagesHandler}
        editRecordHandler={resendOneMessage}
        showLoading={showLoading}
        selectedRowValue={selectedResendMessageUuid}
      />
      <ConfirmationModal
        title={resendMultipleMessages ? 'Re-send All Messages' : 'Re-send Message'}
        text={resendMultipleMessages ? 'Are You Sure to Re-Send All Failed Message' : 'Are You Sure to Re-Send Message'}
        openModal={openConfirmationModal}
        closeModal={closeConfirmationModal}
        submitForm={reSendMessagesHandler}
      />
      <ToastContainer />
    </>
  )
}

export default MessagePage;