import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import Title from '../../components/Title/index';
import { useLocation, useNavigate } from 'react-router-dom';
import { getMessageInfo } from '../../services/app/messages-service';
import { useQuery } from "react-query";
import { errorHandler } from "../../utils/network/error-handler";
import { Box, Grid } from '@mui/material';
import { AxiosError } from "axios";
import { isObject } from 'lodash'


const PageContent = styled('div')(({ theme }) => ({
  marginBottom: '3rem',
}));

const BoxedContent = styled('div')(({ theme }) => ({
  top: '50%',
  left: '20%',
  position: 'relative',
  width: '60vw',
}))

const CenteredTypoGraphy = styled('div')(({ theme }) => ({
  textAlign: 'center',
  marginTop: '2rem'
}))

const MessageDetailPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [ messageUuid, setMessageUuid ] = useState('');
  const [ filteredKeys, setFilteredKeys ] = useState<any>()
  const [ userKeys, setUserKeys ] = useState<any>()
  const [ ticketKeys, setTicketKeys ] = useState<any>()
  const [ redeemKeys, setRedeemKeys ] = useState<any>()

  useEffect(() => {
    const urlArray = location?.pathname?.split('/');
    const uuid = urlArray[urlArray.length - 1];

    uuid && setMessageUuid(uuid);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useQuery(['redeemInfo'], () => getMessageInfo(messageUuid),{
    onSuccess: (data: any) => {
      const { user, ticket, redeem } = data;
      let keys = Object?.keys(data);
      let userKeys = isObject(user) ? Object?.keys(user) : [];
      let ticketKeys = isObject(ticket) ? Object?.keys(ticket) : [];
      let redeemKeys =  isObject(redeem)  ? Object.keys(redeem) : [];
      let nonObjectArr: any = [];
      let userKeysArray: any = [];
      let ticketKeysArray: any = [];
      let redeemKeysArray: any = [];

      keys?.forEach((key: any) => {
        if( data[`${key}`] && !isObject(data[`${key}`])){
          const result = key.replace(/([A-Z])/g, " $1");
          const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
          nonObjectArr.push({
            name: key,
            data: data[key],
            title: finalResult
          })
        }
      });

      setFilteredKeys(nonObjectArr);

      userKeys?.forEach((key: any) => {
        if( data[`${key}`] && !isObject(data[`${key}`])){
          const result = key.replace(/([A-Z])/g, " $1");
          const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
          userKeysArray.push({
            name: key,
            data: data[key],
            title: finalResult
          })
        }
      });


      setUserKeys(userKeysArray);

      ticketKeys?.forEach((key: any) => {
        if(data[`${key}`] && !isObject(data[`${key}`])){
          const result = key.replace(/([A-Z])/g, " $1");
          const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
          ticketKeysArray.push({
            name: key,
            data: data[key],
            title: finalResult
          })
        }
      });

      setTicketKeys(ticketKeysArray);

      redeemKeys?.forEach((key: any) => {
        if(!isObject(data[`${key}`])){
          const result = key.replace(/([A-Z])/g, " $1");
          const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
          redeemKeysArray.push({
            name: key,
            data: data[key],
            title: finalResult
          })
        }
      });

      setRedeemKeys(redeemKeysArray);
    },
    onError: (err: AxiosError) => errorHandler(err, navigate),
    enabled: messageUuid ? true : false
  })

  return (
    <>
      <PageContent>
        <Title title='Redeem Details'/>    
      </PageContent>

      <Box>
        <BoxedContent>
          <CenteredTypoGraphy>
            <h1>
              Message Info
            </h1>
          </CenteredTypoGraphy>
          {
            filteredKeys?.map((key: any) => {
              return (
                <Grid container spacing={3}>
                  <Grid item pl={20} xs={6} sm={6} lg={6}>
                    <h3>{key.title}</h3>
                  </Grid>
                  <Grid item pl={20} xs={6} sm={6} lg={6}>
                    <p>{key.data || "Null"}</p>
                  </Grid>
                </Grid>
              )
            })
          }

          <CenteredTypoGraphy>
            <h1>
              User Info
            </h1>
          </CenteredTypoGraphy>

          {
            userKeys?.map((key: any) => {
              return (
                <Grid container spacing={3}>
                  <Grid item pl={20} xs={6} sm={6} lg={6}>
                    <h3>{key.title}</h3>
                  </Grid>
                  <Grid item pl={20} xs={6} sm={6} lg={6}>
                    <p>{key.data || "Null"}</p>
                  </Grid>
                </Grid>
              )
            })
          }

          <CenteredTypoGraphy>
            <h1>
              Ticket Info
            </h1>
          </CenteredTypoGraphy>

          {
            ticketKeys?.map((key: any) => {
              return (
                <Grid container spacing={3}>
                  <Grid item pl={20} xs={6} sm={6} lg={6}>
                    <h3>{key.title}</h3>
                  </Grid>
                  <Grid item pl={20} xs={6} sm={6} lg={6}>
                    <p>{key.data || "Null"}</p>
                  </Grid>
                </Grid>
              )
            })
          }

          <CenteredTypoGraphy>
            <h1>
              Redeem Info
            </h1>
          </CenteredTypoGraphy>

          {
            redeemKeys?.map((key: any) => {
              return (
                <Grid container spacing={3}>
                  <Grid item pl={20} xs={6} sm={6} lg={6}>
                    <h3>{key.title}</h3>
                  </Grid>
                  <Grid item pl={20} xs={6} sm={6} lg={6}>
                    <p>{key.data || "Null"}</p>
                  </Grid>
                </Grid>
              )
            })
          }

          


        </BoxedContent>
      </Box>
    </>
  )
}

export default MessageDetailPage;