import React, { FC, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { styled } from '@mui/material/styles';
import Title from '../../components/Title/index';
import { getRefundOrders } from '../../services/app/refund-service';
import { AxiosError } from 'axios';
import { errorHandler } from '../../utils/network/error-handler';
import { columns } from './table-columns';
import DataTable from '../../components/DataTable/index';




interface OrdersPageProps {}

const PageContent = styled('div')(({ theme }) => ({
  marginBottom: '3rem',
}));

const RefundsPage: FC<OrdersPageProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [tableSize, setTableSize] = useState({
    default: 10,
    list: [5, 10, 25],
  });

  const [refundOrders, setRefundOrders] = useState({
    data: [],
    cursor: {
      afterCursor: '',
      beforeCursor: '',
    },
  });

  const [currentCursor, setCurrentCursor] = useState({
    name: '',
    value: '',
  });

  const [openOrderModal, setOpenOrderModal] = useState({
    value: false,
    orderUuid: '',
  });

  const [ orderStatus ] = useState<any>({
    refundStatus: [
      'All', 'pending', 'completed', 'declined', 'error'
    ],
  })

  const [refundOrderSearchedValues, setRefundOrderSearchedValues] = useState<any>('')

  const getRefundsQuery = useQuery(
    ['refundOrders', tableSize.default, refundOrderSearchedValues, currentCursor.value],
    async () => getRefundOrders({ 
      limit: tableSize.default,
      orderSearchedValues: refundOrderSearchedValues,
      afterCursor: currentCursor.name === 'next' ? currentCursor.value : '',
      beforeCursor: currentCursor.name === 'previuous' ? currentCursor.value : '', }),
    {
      onSuccess: (data) => {
        const { data: refundData, cursor } = data
        const mappedRefunds = refundData?.map(({ buyer, payment, uuid }: any) => {
          return {
            email: buyer.email,
            uuid,
            ...payment.refund
          }
        })
        setRefundOrders({data: mappedRefunds, ...cursor})
      },
      onError: (err: AxiosError) => errorHandler(err, navigate),
      refetchOnWindowFocus: true,
    },
  );

  const pageSizeHandler = (pageSize: number) => {
    setTableSize({
      ...tableSize,
      default: pageSize,
    });
    getRefundsQuery.refetch();
  };

  const changePageHandler = (changePage: string) => {
    const { cursor } = refundOrders || {};
    const { afterCursor, beforeCursor } = cursor || {};
    if (changePage === 'go_back' && beforeCursor !== '') {
      setCurrentCursor({
        name: 'previous',
        value: beforeCursor,
      });
    } else {
      if (afterCursor !== '') {
        setCurrentCursor({
          name: 'next',
          value: afterCursor,
        });
      }
    }
    getRefundsQuery.refetch();
  };

  const orderModalHandler = (row: any) => {
    setOpenOrderModal(() => {
      return {
        value: true,
        orderUuid: row.uuid,
      };
    });
    navigate(`/orders/${row.uuid}`)
  };

  const orderSearchHandler = (feild: string, value: any) => {

    switch(feild) {
      case 'refund_status':
        if(value == 'All'){
          setRefundOrderSearchedValues('')
          break;
        }
        setRefundOrderSearchedValues(value)
        break;
      default:
        break;
    }

  }

  return (
    <>
      <PageContent>
        <Title title="Refunds" />
      </PageContent>

      <DataTable
        data={refundOrders?.data.length ? refundOrders : []}
        columns={columns}
        buttonText={''}
        rowClickHandler={orderModalHandler}
        pageSizeChangeHandler={(pageSize: number) => pageSizeHandler(pageSize)}
        changePageHandler={changePageHandler}
        tableSize={tableSize}
        inputChangeHandler={orderSearchHandler}
        orderValues={orderStatus}
      />
    </>
  )
}


export default RefundsPage;