import network from '../../utils/network';

interface getTicketProviderUsersParams {
  limit?: number;
  ticketProviderId?: number;
  afterCursor?: string;
  beforeCursor?: string;
}

export const getTicketProviderUsers = async ({
  limit,
  ticketProviderId,
  afterCursor,
  beforeCursor,
}: getTicketProviderUsersParams) => {
  let params: { [key: string]: any } = {};
  params.limit = limit;
  ticketProviderId && (params.ticketProviderId = ticketProviderId);
  afterCursor && (params.afterCursor = afterCursor);
  beforeCursor && (params.beforeCursor = beforeCursor);

  const response = await network.get({
    path: `/ticket-provider-users`,
    options: {
      params,
    },
  });
  return response?.data;
};

export const getTicketProviders = async () => {
  const response = await network.get({
    path: `/ticket-providers/get-all`,
  });
  return response?.data;
};

export const getEvents = async () => {
  const response = await network.get({
    path: `/events`,
  });
  return response?.data;
};

export const createTicketProviderUser = async (data: any) => {
  const response = await network.post('/ticket-provider-users', data);

  return response?.data;
};

export const updateTicketProviderUser = async (ticketProviderUserId: any, data: any) => {
  const response = await network.patch(`/ticket-provider-users/${ticketProviderUserId}`, data);
  return response?.data;
};
