import TicketProvider from '../pages/TicketProvider';
import Ticket from '../pages/Ticket';
import Login from '../pages/Login';
import Users from '../pages/Users';
import TicketProviderApiToken from '../pages/TicketProviderApiToken';
import TicketTransfer from '../pages/TicketTransfer';
import TicketProviderEncryptionKey from '../pages/TicketProviderEncryptionKey';
import Events from '../pages/Events'
import Landing from '../pages/Landing';
import TicketType from '../pages/TicketType/index'
import Orders from '../pages/Orders';
import OrdersDetailPage from '../pages/Orders/OrderDetailPage';
import OutboxPage from '../pages/Outbox';
import ListingPage from '../pages/Listing';
import MessagePage from '../pages/Messages';
import RedeemPage from '../pages/Redeem';
import RedeemDetailPage from '../pages/Redeem/RedeemDetailPage';
import MessageDetailPage from '../pages/Messages/MessageDetailsPage';
import SettingsPage from '../pages/Settings';
import TicketProviderUsers from '../pages/TicketProviderUser/index'
import RefundsPage from '../pages/Refunds';

export const protectedBackendRoutes = [
  {
    backendPath: '/',
    element: Landing,
  },
  {
    backendPath: '/ticket-provider',
    element: TicketProvider,
  },
  {
    backendPath: '/ticket',
    element: Ticket,
  },
  {
    backendPath: '/users',
    element: Users,
  },
  {
    backendPath: '/events',
    element: Events
  },
  {
    backendPath: '/ticket-provider-api-token',
    element: TicketProviderApiToken,
  },
  {
    backendPath: '/ticket-transfer',
    element: TicketTransfer,
  },
  {
    backendPath: '/ticket-provider-encryption-key',
    element: TicketProviderEncryptionKey,
  },
  {
    backendPath: '/ticket-types',
    element: TicketType,
  },
  {
    backendPath: '/orders',
    element: Orders,
  },
  {
    backendPath: '/refund-orders',
    element: RefundsPage,
  },
  {
    backendPath: '/orders/:uuid',
    element: OrdersDetailPage,
  },
  {
    backendPath: '/outbox',
    element: OutboxPage,
  },
  {
    backendPath: '/listing',
    element: ListingPage,
  },
  {
    backendPath: '/messages',
    element: MessagePage,
  },
  {
    backendPath: '/redeem',
    element: RedeemPage,
  },
  {
    backendPath: '/redeem/:uuid',
    element: RedeemDetailPage,
  },
  {
    backendPath: '/messages/:uuid',
    element: MessageDetailPage,
  },
  {
    backendPath: '/settings',
    element: SettingsPage,
  },
  {
    backendPath: '/ticket-provider-users/ticket-provider=:id',
    element: TicketProviderUsers,
  },
];

export const publicRoutes = [
  {
    path: '/login',
    element: Login,
  },
];
