import InboxIcon from '@mui/icons-material/AirplaneTicket';
import SendIcon from '@mui/icons-material/Send';
import PeopleIcon from '@mui/icons-material/People';
import ApiIcon from '@mui/icons-material/Api';
import BusinessIcon from '@mui/icons-material/Business';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import EventIcon from '@mui/icons-material/Event';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import OutboxIcon from '@mui/icons-material/Outbox';
import ListIcon from '@mui/icons-material/List';
import MessageIcon from '@mui/icons-material/Message';
import RedeemIcon from '@mui/icons-material/Redeem';
import SettingsIcon from '@mui/icons-material/Settings';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';

export const sideBarWebMenu = [
  { title: 'Users', icon: PeopleIcon, path: '/users' },
  { title: 'Tickets', icon: InboxIcon, path: '/ticket' },
  { title: 'Ticket Type', icon: EventIcon, path: '/ticket-types' },
  { title: 'Ticket Providers', icon: BusinessIcon, path: '/ticket-provider' },
  { title: 'Ticket Provider Api Token', icon: ApiIcon, path: '/ticket-provider-api-token' },
  {
    title: 'Ticket Provider Encryption Key',
    icon: VpnKeyIcon,
    path: '/ticket-provider-encryption-key',
  },
  { title: 'Ticket Transfer Log', icon: SendIcon, path: '/ticket-transfer' },
  { title: 'Events', icon: EventIcon, path: '/events' },
  { title: 'Orders', icon: LocalGroceryStoreIcon, path: '/orders' },
  { title: 'Refund Orders', icon: AssignmentReturnIcon, path: '/refund-orders' },
  { title: 'Redeem', icon: RedeemIcon, path: '/redeem' },
  { title: 'Listing', icon: ListIcon, path: '/listing' },
  { title: 'Messages', icon: MessageIcon, path: '/messages' },
  { title: 'Outbox', icon: OutboxIcon, path: '/outbox' },
  { title: 'Settings', icon: SettingsIcon, path: '/settings' },
];
