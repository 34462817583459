/* eslint-disable @typescript-eslint/no-unused-expressions */
import network from '../../utils/network';

interface getRedeemsParams {
  limit?: number;
  redeemSearchedParams?: any;
  afterCursor?: string;
  beforeCursor?: string;
}

export const getRedeems = async ({ limit, redeemSearchedParams, afterCursor, beforeCursor }: getRedeemsParams) => {
  let params: { [key: string]: any } = {};
  params.limit = limit;
  redeemSearchedParams.userPhoneNumber && (params.userPhoneNumber = redeemSearchedParams.userPhoneNumber);

  afterCursor ? (params.afterCursor = afterCursor) : '';
  beforeCursor ? (params.beforeCursor = beforeCursor) : '';

  const response = await network.get({
    path: `/redeems`,
    options: {
      params,
    },
  });
  return response?.data;
};

export const getRedeemInfo = async (uuid: string) => {
  const response = await network.get({
    path: `/redeems/${uuid}`,
  });

  return response?.data;
};
