/* eslint-disable @typescript-eslint/no-unused-expressions */
import network from '../../utils/network';

interface getRefundsParams {
  limit?: number;
  ticketProviderId?: string;
  location?: any;
  orderSearchedParams?: any;
  afterCursor?: string;
  beforeCursor?: string;
  orderSearchedValues?: any;
}

export const getRefundOrders = async ({ limit, afterCursor, beforeCursor, orderSearchedValues }: getRefundsParams) => {
  let params: { [key: string]: any } = {};
  params.limit = limit;
  params.withRefunds = true;

  afterCursor ? (params.afterCursor = afterCursor) : '';
  beforeCursor ? (params.beforeCursor = beforeCursor) : '';
  orderSearchedValues && (orderSearchedValues !== 'all' || orderSearchedValues !== 'All')
    ? (params.paymentRefundStatus = orderSearchedValues)
    : '';

  const response = await network.get({
    path: `/orders`,
    options: {
      params,
    },
  });
  return response?.data;
};
