import React, { useState } from 'react';
import { Autocomplete, Box, Button, Grid, Modal, TextField, Switch, FormControlLabel, Divider, Tabs, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

const ModalTitle = styled('h2')(({ theme }) => ({
  overflow: 'hidden',
  textAlign: 'center',
  marginTop: '0px',
}));

const ModalSubTitle = styled('h3')(({ theme }) => ({
  overflow: 'hidden',
  textAlign: 'center',
  marginTop: '2rem',
}));

const ButtonDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '38rem',
  bgcolor: 'background.paper',
  p: 4,
  pt: 3,
  pb: 1,
  borderRadius: 2,
};

const SwitchesDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end'
}))

interface optionType {
  id: number;
  name: string;
  uuid?: string;
  ticketProviderId?: number
}

interface CreateTicketModalProps {
  title: string;
  openModal: boolean;
  closeModal: () => any;
  submitForm: () => any;
  inputValueHandler: (field: string, value: string) => any;
  newUserHandler?: (value: boolean) => any;
  ticketProviders?: any;
  events?: any;
  newUser?: any;
  newUserChangeHandler?: (field: string, value: string | number) => any;
  saleEnableChangeHandler: (field: string, value: string) => any;
  resaleEnableChangeHandler: (field: string, value: string) => void;
  multiDayTicketValuesHandler: (field: string, value: any) => void;
  saleEnabled?: any;
  reSaleEnabled?: any;
  resaleEnabledHandler: (value: boolean) => void;
  createButtonClicked: boolean;
  currencies?: any;
  inputValues?: any
  resaleEnabledValues?: any;
  saleEnabledValues?: any;
  multiDayTicket?: any
}

const CreateTicketTypeModal: React.FC<CreateTicketModalProps> = ({
  title,
  openModal,
  closeModal,
  submitForm,
  inputValueHandler,
  events,
  saleEnabled,
  reSaleEnabled,
  saleEnableChangeHandler,
  resaleEnabledHandler,
  resaleEnableChangeHandler,
  currencies,
  inputValues,
  resaleEnabledValues,
  saleEnabledValues,
  multiDayTicket,
  multiDayTicketValuesHandler,
  createButtonClicked
}) => {
  const [dateStart, setDateStart] = useState<any>(dayjs(new Date()).format('YYYY-MM-DD'));
  const [dateEnd, setDateEnd] = useState<any>(dayjs(new Date()));

  const [saleEnabledateStart, setSaleEnableDateStart] = useState<Dayjs | null>(dayjs(new Date()));
  const [saleEnabledateEnd, setSaleEnableDateEnd] = useState<Dayjs | null>(dayjs(new Date()));
  const [saleCurrency, setSaleCurrency] = useState<any>(null);
  const [reSaleCurrency, setReSaleCurrency] = useState<any>(null);
  const [ tabValue, setTabValue ] = useState(0)
  const [ticketTypeStatus] = useState([
    {name: 'Active', value: 'active'},
    {name: 'Inactive', value: 'inactive'},
    {name: 'Upcoming', value: 'upcoming'},
    {name: 'Sold Out', value: 'sold_out'},
    {name: 'Deleted', value: 'deleted'}
  ])
  
  return (
    <>
      <Modal open={openModal} onClose={closeModal} aria-labelledby="modal-title">
        <Box sx={style}>
          <ModalTitle id="modal-title">{title}</ModalTitle>
          <SwitchesDiv>
            <FormControlLabel
              value="start"
              control={<Switch color="primary" onChange={(e) => {
                if(!e.target.checked){
                  setReSaleCurrency(null)
                }
                resaleEnabledHandler(e.target.checked)
              }} />}
              label={<strong>Resale Enable</strong>}
              labelPlacement="start"
              style={{ display: 'flex' }}
            />
            
            <FormControlLabel
              value="start"
              control={<Switch color="primary" onChange={(e) => multiDayTicketValuesHandler('is_multi_day_ticket', e.target.checked)} />}
              label={<strong>Is Multi-day</strong>}
              labelPlacement="start"
              style={{ display: 'flex' }}
            />
          </SwitchesDiv>
          <Tabs value={tabValue} onChange={(e: any, newValue: number) => setTabValue(newValue)}>
            <Tab label="English"/>
            <Tab label="Portugiese"/>
          </Tabs>

          {
            tabValue === 0 && (
              <Grid container spacing={3} sx={{pt: 3}}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={createButtonClicked && (inputValues.name.length === 0 || inputValues.name[0] === '') ? true : false}
                    helperText={createButtonClicked && (inputValues.name.length === 0 || inputValues.name[0] === '') ? 'Ticket Type English name is required' : ''}
                    required
                    id="ticketType"
                    name="type"
                    label="Ticket Type"
                    fullWidth
                    autoComplete="given-name"
                    variant="standard"
                    value={inputValues?.name[0]}
                    onChange={(e) => inputValueHandler('english_type', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="ticketType"
                    name="type"
                    label="Description"
                    error={createButtonClicked && (inputValues.description.length === 0 || inputValues.description[0] === '') ? true : false}
                    helperText={createButtonClicked && (inputValues.description.length === 0 || inputValues.description[0] === '') ? 'Ticket Type English Description is required' : ''}
                    fullWidth
                    autoComplete="given-name"
                    value={inputValues?.description[0]}
                    variant="standard"
                    onChange={(e) => inputValueHandler('english_description', e.target.value)}
                  />
                </Grid>
              </Grid>
            )
          }

          {
            tabValue === 1 && (
              <Grid container spacing={3} sx={{pt: 3}}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="ticketType"
                    name="type"
                    error={createButtonClicked && (inputValues.name.length === 0 || inputValues.name[1] === '') ? true : false}
                    helperText={createButtonClicked && (inputValues.name.length === 0 || inputValues.name[1] === '') ? 'Ticket Type Portugiese name is required' : ''}
                    label="Tipo de bilhete"
                    fullWidth
                    autoComplete="given-name"
                    variant="standard"
                    value={inputValues?.name[1]}
                    onChange={(e) => inputValueHandler('portuguese_type', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="ticketType"
                    name="type"
                    label="Descrição"
                    error={createButtonClicked && (inputValues.description.length === 0 || inputValues.description[1] === '') ? true : false}
                    helperText={createButtonClicked && (inputValues.description.length === 0 || inputValues.description[1] === '') ? 'Ticket Type Portugiese Description is required' : ''}
                    fullWidth
                    autoComplete="given-name"
                    variant="standard"
                    value={inputValues?.description[1]}
                    onChange={(e) => inputValueHandler('portuguese_description', e.target.value)}
                  />
                </Grid>
              </Grid> 
            )
          }
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Ticket Start Date*"
                  value={dateStart}
                  onChange={(newValue: Dayjs | null) => {
                    setDateStart(newValue);
                    inputValueHandler('dateStart', newValue !== null ? newValue.format('YYYY-MM-DD') : '');
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Ticket End Date"
                  value={dateEnd}
                  onChange={(newValue: Dayjs | null) => {
                    setDateEnd(newValue);
                    inputValueHandler('dateEnd', newValue !== null ? newValue.format('YYYY-MM-DD') : '');
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={events.filter((event: any) => event.name !== 'All')}
                getOptionLabel={(option: optionType) => option.name}
                autoComplete
                includeInputInList
                onChange={(e: any, newValue: optionType | null) => {
                  inputValueHandler('eventId', newValue ? `${newValue?.uuid}_${newValue.ticketProviderId}` : '');
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={createButtonClicked && inputValues.eventId === '' ? true : false}
                    helperText={createButtonClicked && inputValues.eventId === '' ? 'Event is required' : ''}
                    label="Event *"
                    fullWidth
                    variant="standard" 
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={ticketTypeStatus.filter((day: any) => day.name)}
                getOptionLabel={(option: any) => option.name}
                autoComplete
                includeInputInList
                onChange={(e: any, newValue: any | null) => {
                  inputValueHandler('ticket_type_status', newValue?.value ? newValue?.value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={createButtonClicked && inputValues.status === '' ? true : false}
                    helperText={createButtonClicked && inputValues.status === '' ? 'Event is required' : ''}
                    label="Ticket Type Status*"
                    fullWidth
                    variant="standard"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                id="imageUrl"
                name="imageUrl"
                label="Image URL"
                fullWidth
                autoComplete="given-image-url"
                variant="standard"
                onChange={(e) => inputValueHandler('imageUrl', e.target.value)}
              />
            </Grid>
          </Grid>


          <div style={{ display: saleEnabled.saleEnabled ? 'block' : 'none' }}>
            <Divider />

            <ModalSubTitle>Sale Enable Data</ModalSubTitle>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="saleAmountPrice"
                  name="saleAmountPrice"
                  label="Sale Amount"
                  fullWidth
                  value={saleEnabledValues?.saleEnableValues?.saleAmount}
                  autoComplete="given-name"
                  variant="standard"
                  onChange={(e) => saleEnableChangeHandler('sale_amount', e.target.value)}
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={currencies}
                  getOptionLabel={(option: optionType) => option.name}
                  autoComplete
                  includeInputInList
                  value={saleCurrency}
                  onChange={(e: any, newValue: optionType | null) => {
                    setSaleCurrency(newValue)
                    saleEnableChangeHandler('sale_currency', newValue ? `${newValue?.uuid}` : '');
                  }}
                  renderInput={(params) => <TextField {...params} label="Sale Currency *" fullWidth variant="standard" />}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={3}>
              <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date*"
                  value={saleEnabledateStart}
                  onChange={(newValue: Dayjs | null) => {
                    setSaleEnableDateStart(newValue);
                    saleEnableChangeHandler('sale_start_date', newValue !== null ? newValue.format('YYYY-MM-DD') : '');
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date*"
                  value={saleEnabledateEnd}
                  onChange={(newValue: Dayjs | null) => {
                    setSaleEnableDateEnd(newValue);
                    saleEnableChangeHandler('end_date', newValue !== null ? newValue.format('YYYY-MM-DD') : '');
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              </Grid>
            </Grid>
          </div>


          <div style={{ display: reSaleEnabled.resaleEnabled ? 'block' : 'none' }}>
            <Divider />

            <ModalSubTitle>Re-Sale Enable Data</ModalSubTitle>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="resaleMaxAmount"
                  name="resaleMaxAmount"
                  label="Re-Sale Max Amount"
                  fullWidth
                  error={createButtonClicked && reSaleEnabled.resaleEnabled && resaleEnabledValues?.resaleEnableValues?.resaleMaxPrice === '' ? true : false}
                  helperText={createButtonClicked && reSaleEnabled.resaleEnabled && resaleEnabledValues?.resaleEnableValues?.resaleMaxPrice === '' ? 'Re-sale Max Price is required' : ''}
                  value={resaleEnabledValues?.resaleEnableValues?.resaleMaxPrice}
                  autoComplete="given-name"
                  variant="standard"
                  onChange={(e) => resaleEnableChangeHandler('max_amount', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="resaleMinAmount"
                  name="resaleMinAmount"
                  label="Re-Sale Min Amount"
                  fullWidth
                  error={createButtonClicked && reSaleEnabled.resaleEnabled && resaleEnabledValues?.resaleEnableValues?.resaleMinPrice === '' ? true : false}
                  helperText={createButtonClicked && reSaleEnabled.resaleEnabled && resaleEnabledValues?.resaleEnableValues?.resaleMinPrice === '' ? 'Re-sale Min Price is required' : ''}
                  value={resaleEnabledValues?.resaleEnableValues?.resaleMinPrice}
                  autoComplete="given-name"
                  variant="standard"
                  onChange={(e) => resaleEnableChangeHandler('min_amount', e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={3}>
              <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date*"
                  value={saleEnabledateStart}
                  onChange={(newValue: Dayjs | null) => {
                    setSaleEnableDateStart(newValue);
                    resaleEnableChangeHandler('resale_start_date', newValue !== null ? newValue.format('YYYY-MM-DD') : '');
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date*"
                  value={saleEnabledateEnd}
                  onChange={(newValue: Dayjs | null) => {
                    setSaleEnableDateEnd(newValue);
                    resaleEnableChangeHandler('resale_end_date', newValue !== null ? newValue.format('YYYY-MM-DD') : '');
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={3} >
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={currencies}
                  getOptionLabel={(option: optionType) => option.name}
                  value={reSaleCurrency}
                  autoComplete
                  includeInputInList
                  onChange={(e: any, newValue: optionType | null) => {
                    setReSaleCurrency(newValue)
                    resaleEnableChangeHandler('resale_currency', newValue ? `${newValue?.uuid}` : '');
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Re-Sale Currency *"
                      fullWidth
                      variant="standard"
                      error={createButtonClicked && reSaleEnabled.resaleEnabled && resaleEnabledValues?.resaleEnableValues?.currency === '' ? true : false}
                      helperText={createButtonClicked && reSaleEnabled.resaleEnabled && resaleEnabledValues?.resaleEnableValues?.currency === '' ? 'Re-sale Currency is required' : ''}
                    />
                  )}
                />
              </Grid>

            </Grid>
          </div>

          <div style={{ display: multiDayTicket.isMultiDay ? 'block' : 'none' }} >
            <Divider />

            <ModalSubTitle>Maximum Validations</ModalSubTitle>
            
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="isMultiDay"
                  name="resaleMaxAmount"
                  label="Max Validations Per Day"
                  type="number"
                  fullWidth
                  value={multiDayTicket?.multiDayTicketValues?.maximumValidationsPerDay}
                  autoComplete="given-name"
                  variant="standard"
                  onChange={(e: any) => {
                    if (e.target.value <= 0) {
                      multiDayTicketValuesHandler('maximum_validations_per_day', 1);
                      return;
                    }
        
                    multiDayTicketValuesHandler('maximum_validations_per_day', e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </div>

          <ButtonDiv>
            <Button variant="contained" onClick={() => {
              setReSaleCurrency(null)
              setSaleCurrency(null)
              closeModal()
            }} sx={{ mt: 3, ml: 1 }} color="inherit">
              Close
            </Button>
            <Button variant="contained" onClick={() => {
              setSaleCurrency(null)
              setReSaleCurrency(null)
              submitForm()
            }} sx={{ mt: 3, ml: 1 }} color="primary">
              Create
            </Button>
          </ButtonDiv>
        </Box>
      </Modal>
    </>
  );
};

export default CreateTicketTypeModal;
