import React, { FC, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { styled } from '@mui/material/styles';
import Title from '../../components/Title/index';
import DataTable from '../../components/DataTable/index';
import { columns } from './table-columns';
import { getTicketTypes, createTicketTypeService, getCurrencies } from '../../services/app/ticket-type-service';
import { AxiosError } from 'axios';
import { errorHandler } from '../../utils/network/error-handler';
import { getEvents } from '../../services/app/events-service';
import CreateTicketTypeModal from './CreateTicketTypeModal';
import { toast, ToastContainer } from 'react-toastify';
import dayjs from 'dayjs';

export interface TicketTypeInterface {}

export interface CreateTicketTypeInterface {
  eventUuid: string;
  names: string;
  ticketDateStart: string;
  ticketDateEnd: string;
  saleEnabled?: number;
  saleAmount?: number;
  saleEnabledFromDate?: string;
  saleEnabledToDate?: string;
  resaleEnabled?: number;
  resaleMaxPrice?: number;
  resaleMinPrice?: number;
  resaleEnabledFromDate?: string;
  resaleEnabledToDate?: string;
  resaleCurrency?: string;
  saleCurrency?: string;
  descriptions: string;
  ticketProviderId: number;
  isMultiDay?: number;
  maxValidationsPerDay?: number;
  status: string;
  locales: string[];
  imageUrl?: string;
}

const PageContent = styled('div')(({ theme }) => ({
  marginBottom: '3rem',
}));

const TicketType: FC<TicketTypeInterface> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [ticketTypes, setTicketTypes] = useState({
    data: [],
    cursor: {
      afterCursor: '',
      beforeCursor: '',
    },
  });
  const [events, setEvents] = useState([]);
  const [eventsFilterValue, setEventsFilterValue] = useState('');
  const [ticketProviderId, setFilteredTicketProviderId] = useState('');
  const [tableSize, setTableSize] = useState({
    default: 10,
    list: [5, 10, 25],
  });
  const [openTicketTypeModal, setOpenTicketTypeModal] = useState(false);
  const [saleEnabledState, setSaleEnabledState] = useState({
    saleEnabled: false,
    saleEnableValues: {
      saleAmount: '',
      saleEnabledFromDate: dayjs(new Date()).format('YYYY-MM-DD'),
      saleEnabledToDate: dayjs(new Date()).format('YYYY-MM-DD'),
      currency: '',
    },
  });

  const [resaleEnabledState, setResaleEnabledState] = useState({
    resaleEnabled: false,
    resaleEnableValues: {
      resaleMaxPrice: '',
      resaleMinPrice: '',
      resaleEnabledFromDate: dayjs(new Date()).format('YYYY-MM-DD'),
      resaleEnabledToDate: dayjs(new Date()).format('YYYY-MM-DD'),
      currency: '',
    },
  });

  const [multiDayTicket, setMultiDayTicket] = useState({
    isMultiDay: false,
    multiDayTicketValues: {
      maximumValidationsPerDay: 1,
    },
  });

  const [ticketTypeValues, setTicketTypeValues] = useState<any>({
    eventId: '',
    name: [],
    ticketDateStart: dayjs(new Date()).format('YYYY-MM-DD'),
    ticketDateEnd: dayjs(new Date()).format('YYYY-MM-DD'),
    description: [],
    ticketProviderId: '',
    locales: ['en-US', 'pt-BR'],
    status: '',
    imageUrl: '',
  });

  const [currencies, setCurrencies] = useState([]);
  const [createButtonClicked, setCreateButtonClicked] = useState(false)

  useQuery(['events'], () => getEvents({ location }), {
    onSuccess: (data) => {
      let events = [...data.data];

      events.unshift({
        name: 'All',
        uuid: '0',
      });
      setEventsFilterValue(events[0]?.uuid);
      setEvents(events as any);
      getTicketTypesQuery.refetch();
    },
    refetchOnWindowFocus: true,
  });

  useQuery(['currencies'], () => getCurrencies(location), {
    onSuccess: (data) => {
      let arr: any = [];
      Object.keys(data).forEach((key) => {
        let obj: any = {};
        obj['name'] = key;
        obj['uuid'] = key;
        arr.push(obj);
      });
      setCurrencies(arr);
    },
  });

  const getTicketTypesQuery = useQuery(
    [
      location.pathname.split('/').includes('validate-backend') ? 'ticket-types' : 'ticket-type',
      tableSize.default,
      eventsFilterValue,
    ],
    () =>
      getTicketTypes({
        limit: tableSize.default,
        location,
        eventUuid: eventsFilterValue,
        ticketProviderId: ticketProviderId,
      }),
    {
      onSuccess: (data) => {
        setTicketTypes(data);
      },
      onError: (err: AxiosError) => errorHandler(err, navigate),
      refetchOnWindowFocus: true,
    },
  );

  const createTicketTypeQuery = useMutation(
    (data: CreateTicketTypeInterface) => createTicketTypeService(data, location),
    {
      onSuccess: (data) => {
        closeModal();
      },
      onError: (err: AxiosError) => errorHandler(err, navigate),
    },
  );

  const pageSizeHandler = (pageSize: number) => {
    setTableSize({
      ...tableSize,
      default: pageSize,
    });
    getTicketTypesQuery.refetch();
  };

  const eventsHandler = (eventUuid: string) => {
    const foundedEvent: any = events?.find((item: any) => item.uuid === eventUuid);
    setFilteredTicketProviderId(foundedEvent?.ticketProviderId);
    setEventsFilterValue(eventUuid);
  };

  const openModal = () => {
    setOpenTicketTypeModal(true);
  };

  const closeModal = () => {
    setCreateButtonClicked(false)
    setOpenTicketTypeModal(false);
    clearState('sales_enabled_state')
    clearState('is_multi_day_ticket')
    clearState('re-sales_enabled_state')
    clearState('basic_data')
  };

  const createTicketType = () => {
    setCreateButtonClicked(true)
    const { eventId, name, ticketDateStart, ticketDateEnd, description, ticketProviderId, locales, status, imageUrl } =
      ticketTypeValues;
    const { saleEnabled, saleEnableValues } = saleEnabledState;
    const { saleAmount, saleEnabledFromDate, saleEnabledToDate } = saleEnableValues;
    const { resaleEnabled, resaleEnableValues } = resaleEnabledState;
    const { resaleMinPrice, resaleMaxPrice, resaleEnabledFromDate, resaleEnabledToDate } = resaleEnableValues;
    const { isMultiDay, multiDayTicketValues } = multiDayTicket;
    const { maximumValidationsPerDay } = multiDayTicketValues;
    if (!eventId || name.length < 2 || !ticketDateStart || !ticketDateEnd || description.length < 2 || !status) {
      toast.error('Fill All the Fields', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return;
    }

    let mutationParam: CreateTicketTypeInterface = {
      eventUuid: eventId,
      names: name,
      ticketDateStart,
      ticketDateEnd,
      descriptions: description,
      ticketProviderId,
      locales,
      isMultiDay: 0,
      status,
    };

    mutationParam.isMultiDay = 0;
    imageUrl && (mutationParam['imageUrl'] = imageUrl);

    if (status === 'active') {
      if (!saleAmount || !saleEnabledFromDate || !saleEnabledToDate) {
        toast.error('Fill All Sale Fields', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        return;
      }
      mutationParam['saleAmount'] = parseInt(saleAmount);
      mutationParam['saleEnabledFromDate'] = saleEnabledFromDate;
      mutationParam['saleEnabledToDate'] = saleEnabledToDate;
      mutationParam['saleCurrency'] = saleEnableValues.currency;
    }
    if (resaleEnabled) {
      if (!resaleMinPrice || !resaleMaxPrice || !resaleEnabledFromDate || !resaleEnabledToDate) {
        toast.error('Fill All Re-Sale Fields', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        return;
      }
      mutationParam['resaleEnabled'] = resaleEnabled ? 1 : 0;
      mutationParam['resaleMaxPrice'] = parseInt(resaleMaxPrice);
      mutationParam['resaleMinPrice'] = parseInt(resaleMinPrice);
      mutationParam['resaleEnabledFromDate'] = resaleEnabledFromDate;
      mutationParam['resaleEnabledToDate'] = resaleEnabledToDate;
      mutationParam['resaleCurrency'] = resaleEnableValues.currency;
    }
    if (isMultiDay) {
      mutationParam['isMultiDay'] = 1;
      mutationParam['maxValidationsPerDay'] = maximumValidationsPerDay;
    }
    createTicketTypeQuery.mutate(mutationParam);
  };

  const createTicketTypeFormValuesHandler = (field: string, value: string) => {
    let newValues = { ...ticketTypeValues };

    switch (field) {
      case 'eventId':
        newValues.eventId = value.split('_')[0];
        newValues.ticketProviderId = parseInt(value.split('_')[1]);
        break;
      case 'english_type':
        newValues.name[0] = value;
        break;
      case 'portuguese_type':
        newValues.name[1] = value;
        break;
      case 'dateStart':
        newValues.ticketDateStart = value;
        break;
      case 'ticket_type_status':
        newValues.status = value;
          saleEnabledStateHandler(value)
        break;
      case 'english_description':
        newValues.description[0] = value;
        break;
      case 'imageUrl':
        newValues.imageUrl = value;
        break;
      case 'portuguese_description':
        newValues.description[1] = value;
        break;
      default:
        newValues.ticketDateEnd = value;
    }
    setTicketTypeValues(newValues);
  };

  const saleEnableChangeHandler = (field: string, value: string) => {
    let newValues = { ...saleEnabledState.saleEnableValues };

    switch (field) {
      case 'sale_amount':
        newValues.saleAmount = value;
        break;
      case 'sale_start_date':
        newValues.saleEnabledFromDate = value;
        break;
      case 'sale_currency':
        newValues.currency = value;
        break;
      default:
        newValues.saleEnabledToDate = value;
    }

    setSaleEnabledState({
      ...saleEnabledState,
      saleEnableValues: newValues,
    });
  };

  const resalesEnableChangeHandler = (field: string, value: string) => {
    let newValues = { ...resaleEnabledState.resaleEnableValues };

    switch (field) {
      case 'max_amount':
        newValues.resaleMaxPrice = value;
        break;
      case 'min_amount':
        newValues.resaleMinPrice = value;
        break;
      case 'resale_start_date':
        newValues.resaleEnabledFromDate = value;
        break;
      case 'resale_currency':
        newValues.currency = value;
        break;
      default:
        newValues.resaleEnabledToDate = value;
    }

    setResaleEnabledState({
      ...resaleEnabledState,
      resaleEnableValues: newValues,
    });
  };

  const multiDayTicketValuesHandler = (field: string, value: any) => {
    const newValues = { ...multiDayTicket };

    if (field === 'is_multi_day_ticket' && !value) {
      clearState('is_multi_day_ticket');
      return;
    }

    switch (field) {
      case 'is_multi_day_ticket':
        newValues.isMultiDay = value;
        break;

      case 'maximum_validations_per_day':
        newValues.multiDayTicketValues.maximumValidationsPerDay = value;
        break;

      default:
        break;
    }

    setMultiDayTicket((prevState) => {
      return {
        ...prevState,
        ...newValues,
      };
    });
  };

  const clearState = (state: string) => {
    switch (state) {
      case 'is_multi_day_ticket':
        setMultiDayTicket({
          isMultiDay: false,
          multiDayTicketValues: {
            maximumValidationsPerDay: 1,
          },
        });
        break;
      case 'sales_enabled_state':
        setSaleEnabledState({
          saleEnabled: false,
          saleEnableValues: {
            saleAmount: '',
            saleEnabledFromDate: dayjs(new Date()).format('YYYY-MM-DD'),
            saleEnabledToDate: dayjs(new Date()).format('YYYY-MM-DD'),
            currency: '',
          },
        });
        break;
      case 'basic_data': 
        setTicketTypeValues({
          eventId: '',
          name: [],
          status: '',
          ticketDateStart: dayjs(new Date()).format('YYYY-MM-DD'),
          ticketDateEnd: dayjs(new Date()).format('YYYY-MM-DD'),
          description: [],
          locales: ['en-US', 'pt-BR'],
          imageUrl: '',
        });
        break;
      case 're-sales_enabled_state':
        setResaleEnabledState({
          resaleEnabled: false,
          resaleEnableValues: {
            resaleMaxPrice: '',
            resaleMinPrice: '',
            resaleEnabledFromDate: dayjs(new Date()).format('YYYY-MM-DD'),
            resaleEnabledToDate: dayjs(new Date()).format('YYYY-MM-DD'),
            currency: '',
          },
        }); 
        break;

      default:
        break;
    }
  };

  const saleEnabledStateHandler = (value: string) => {
    if(value !== 'active'){
      setSaleEnabledState({
        saleEnabled: false,
        saleEnableValues: {
          saleAmount: '',
          saleEnabledFromDate: dayjs(new Date()).format('YYYY-MM-DD'),
          saleEnabledToDate: dayjs(new Date()).format('YYYY-MM-DD'),
          currency: '',
        },
      });
    }else {
      setSaleEnabledState({ ...saleEnabledState, saleEnabled: true });
    }
  }

  return (
    <>
      <PageContent>
        <Title title="Ticket Types" />
      </PageContent>
      <DataTable
        data={ticketTypes?.data.length ? ticketTypes : []}
        columns={columns}
        buttonText={location.pathname.split('/')[1] === 'validate-web-backend' ? '' : 'Create'}
        tableSize={tableSize}
        pageSizeChangeHandler={pageSizeHandler}
        eventsHandler={eventsHandler}
        events={events}
        createClickHandler={openModal}
        eventsFilterValue={eventsFilterValue}
      />
      <CreateTicketTypeModal
        title="Create Ticket Type"
        openModal={openTicketTypeModal}
        closeModal={closeModal}
        submitForm={createTicketType}
        inputValueHandler={(field: string, value: string) => createTicketTypeFormValuesHandler(field, value)}
        saleEnabled={saleEnabledState}
        reSaleEnabled={resaleEnabledState}
        events={events}
        saleEnableChangeHandler={saleEnableChangeHandler}
        resaleEnableChangeHandler={resalesEnableChangeHandler}
        resaleEnabledHandler={(value) => {
          if (!value) {
            setResaleEnabledState({
              resaleEnabled: value,
              resaleEnableValues: {
                resaleMaxPrice: '',
                resaleMinPrice: '',
                resaleEnabledFromDate: dayjs(new Date()).format('YYYY-MM-DD'),
                resaleEnabledToDate: dayjs(new Date()).format('YYYY-MM-DD'),
                currency: '',
              },
            });
          } else {
            setResaleEnabledState({ ...resaleEnabledState, resaleEnabled: value });
          }
        }}
        currencies={currencies}
        inputValues={ticketTypeValues}
        resaleEnabledValues={resaleEnabledState}
        saleEnabledValues={saleEnabledState}
        multiDayTicket={multiDayTicket}
        multiDayTicketValuesHandler={multiDayTicketValuesHandler}
        createButtonClicked={createButtonClicked}
      />
      <ToastContainer />
    </>
  );
};

export default TicketType;
