import React, { FC, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { styled } from '@mui/material/styles';
import DataTable from '../../components/DataTable/index';
import Title from '../../components/Title/index';
import { columns } from './table-columns';
import { debounce } from 'lodash';
import { getTicketProviders, getTicketProviderUsers, getEvents, createTicketProviderUser, updateTicketProviderUser } from '../../services/app/ticket-provider-users-service'
import { errorHandler } from '../../utils/network/error-handler';
import CreateTicketProviderUserModal from './CreateTicketProviderUserModal'



const PageContent = styled('div')(({ theme }) => ({
  marginBottom: '3rem',
}));

const TicketProviderUser: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [ticketProviderUsers, setTicketProviderUsers] = useState({
    data: [],
    cursor: {
      afterCursor: '',
      beforeCursor: '',
    },
  })

  const [tableSize, setTableSize] = useState({
    default: 10,
    list: [5, 10, 25],
  });
  const [currentCursor, setCurrentCursor] = useState({
    name: '',
    value: '',
  });

  const [openTicketProviderUserCreateModal, setOpenTicketProviderUserCreateModal ] = useState(false)
  const [shouldUpdateUser, setShouldUpdateUser ] = useState(false)
  const [selectedTicketProviderUserId, setSelectedTicketProviderUserId ] = useState('')
  const [ticketProviders, setTicketProviders ] = useState([])
  const [events, setEvents ] = useState([])
  const [ticketProviderUserCreateValue, setTicketProviderUserCreateValue] = useState<any>({
    eventId: '',
    role: '',
    login: '',
    password: '',
    ticketProviderId: '',
    status: ''
  })

  useQuery(['ticket_providers'], () => getTicketProviders(), {
    onSuccess: (data) => {
      setTicketProviders(data as any);
      setTicketProviderUserCreateValue({
        ...ticketProviderUserCreateValue,
        ticketProviderId: parseInt(data[0].id),
      });
    },
    onError: (err: AxiosError) => errorHandler(err, navigate),
  });

  useQuery(['events'], () => getEvents(), {
    onSuccess: (data) => {
      setEvents(data.data)
    },
    onError: (err: AxiosError) => errorHandler(err, navigate),
  })

  const getTicketProviderUsersQuery = useQuery(['ticket-provider-users', tableSize.default, currentCursor.value],
    () =>
      getTicketProviderUsers({
        limit: tableSize.default,
        afterCursor: currentCursor.name === 'next' ? currentCursor.value : '',
        beforeCursor: currentCursor.name === 'previuous' ? currentCursor.value : '',
        ticketProviderId: parseInt(location.pathname.split('=')[1])
      }),
      {
        onSuccess: (data) => {
          setTicketProviderUsers(data)
        },
        onError: (err: AxiosError) => errorHandler(err, navigate),
      }
  )

  const createTicketProviderUserQuery = useMutation((data: any) => createTicketProviderUser(data), {
    onSuccess: (data) => {
      if(data?.id){
        setOpenTicketProviderUserCreateModal(false);
        setTicketProviderUserCreateValue({
          eventId: '',
          role: '',
          login: '',
          password: '',
          ticketProviderId: '',
          status: ''
        })
        toast.success('Ticket Provider User is created', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    },
    onError: (err: AxiosError) => errorHandler(err, navigate)
  })

  const updateTicketProviderUserQuery = useMutation((data: any) => updateTicketProviderUser(selectedTicketProviderUserId, data), {
    onSuccess: (data) => {
      if(data?.id){
        setOpenTicketProviderUserCreateModal(false);
        setTicketProviderUserCreateValue({
          eventId: '',
          role: '',
          login: '',
          password: '',
          ticketProviderId: '',
          status: ''
        })
        setSelectedTicketProviderUserId('');
        toast.success('Ticket Provider User is updated', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    },
    onError: (err: AxiosError) => errorHandler(err, navigate)
  })

  const editTicketProviderUserHandler = (ticketProviderUserRow: any) => {
    setTicketProviderUserCreateValue({
      eventId: ticketProviderUserRow.eventId,
      login: ticketProviderUserRow.login,
      role: ticketProviderUserRow.role,
      status: ticketProviderUserRow.status,
      ticketProviderId: ticketProviderUserRow.ticketProviderId
    })
    setSelectedTicketProviderUserId(ticketProviderUserRow.id)
    setShouldUpdateUser(true)
    openModal();
  }

  const openModal = () => {
    setOpenTicketProviderUserCreateModal(true)
  }
  
  const closeModal = () => {
    setOpenTicketProviderUserCreateModal(false);
    setTicketProviderUserCreateValue({
      eventId: '',
      role: '',
      login: '',
      password: '',
      ticketProviderId: '',
      status: ''
    })
    setShouldUpdateUser(false);
  }

  const pageSizeHandler = (pageSize: number) => {
    setTableSize({
      ...tableSize,
      default: pageSize,
    });
    getTicketProviderUsersQuery.refetch();
  };

  const changePageHandler = (changePage: string) => {
    const { cursor } = ticketProviderUsers || {};
    const { afterCursor, beforeCursor } = cursor || {};
    if (changePage === 'go_back' && beforeCursor !== '') {
      setCurrentCursor({
        name: 'previous',
        value: beforeCursor,
      });
    } else {
      if (afterCursor !== '') {
        setCurrentCursor({
          name: 'next',
          value: afterCursor,
        });
      }
    }
    getTicketProviderUsersQuery.refetch();
  };

  const inputValueChangeHandler = (field: string, value: string | number, update?: boolean) => {
    switch(field){
      case 'login': 
        setTicketProviderUserCreateValue({
          ...ticketProviderUserCreateValue,
          login: value
        })
      break;
      case 'password': 
        setTicketProviderUserCreateValue({
          ...ticketProviderUserCreateValue,
          password: value
        })
      break;

      case 'status': 
        setTicketProviderUserCreateValue({
          ...ticketProviderUserCreateValue,
          status: value
        })
      break;

      case 'ticketProviderId': 
        setTicketProviderUserCreateValue({
          ...ticketProviderUserCreateValue,
          ticketProviderId: value
        })
      break;
      
      case 'eventId': 
        setTicketProviderUserCreateValue({
          ...ticketProviderUserCreateValue,
          eventId: value
        })
      break;
      
      case 'role':
        if(value === 'admin'){
          setTicketProviderUserCreateValue({
            ...ticketProviderUserCreateValue,
            role: value,
            eventId: '',
          })
          break;
        }
        setTicketProviderUserCreateValue({
          ...ticketProviderUserCreateValue,
          role: value,
        })
      break;
      default: 
      break;

    }
  }

  const mapData = () => {
    const { eventId, role, login, password, ticketProviderId, status } = ticketProviderUserCreateValue;
    let data: { [key: string]: any } = {};

    if((role === 'admin' && (!eventId)) || (role !== 'admin' && eventId)){
      if(shouldUpdateUser){
        if(!login || !ticketProviderId || !status){
          toast.error('Please Fill All the fields', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          return { success: false };
        }
      }else {
        if(!login || !password || !ticketProviderId || !status){
          toast.error('Please Fill All the fields', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          return { success: false };
        }
      }
      data['login'] = login
      data['ticketProviderId'] = ticketProviderId
      data['status'] = status
      password && (data['password'] = password)
      role && (data['role'] = role)
      eventId && (data['eventId'] = eventId)

      return {
        success: true,
        data
      }

    }
    toast.error('Please Fill All the fields', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });

    return { success: false }

  }

  const submitFormHandler = () => {
    const {success, data} = mapData();
    if(success){
      createTicketProviderUserQuery.mutate(data)
    }
  }
  
  const updateSubmitHandler = () => {
    const {success, data} = mapData();
    if(success){
      updateTicketProviderUserQuery.mutate(data)
    }
  }


  return (
    <>
      <PageContent>
        <Title title="Ticket Provider Users" />
      </PageContent>

      <DataTable
        data={ticketProviderUsers?.data?.length ? ticketProviderUsers : []}
        editRecordHandler={editTicketProviderUserHandler}
        columns={columns}
        createClickHandler={openModal}
        buttonText={location.pathname.split('/')[1] === 'validate-web-backend' ? '' : "Create"}
        pageSizeChangeHandler={(pageSize: number) => pageSizeHandler(pageSize)}
        tableSize={tableSize}
        changePageHandler={changePageHandler}
        updateAble={true}
      />
      <CreateTicketProviderUserModal
        title="Ticket Provider User"
        openModal={openTicketProviderUserCreateModal}
        closeModal={closeModal}
        submitForm={submitFormHandler}
        inputValueHandler={inputValueChangeHandler}
        ticketProviders={ticketProviders}
        events={events}
        userObject={ticketProviderUserCreateValue}
        shouldUpdateUser={shouldUpdateUser}
        updateUser={updateSubmitHandler}
      />
      <ToastContainer />
    </>
  )
}

export default TicketProviderUser;