import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Title from '../../components/Title/index';
import DataTable from '../../components/DataTable/index';
import { columns } from './table-column';
import { useQuery } from 'react-query';
import { errorHandler } from '../../utils/network/error-handler';
import { AxiosError } from 'axios';
import { getRedeems } from '../../services/app/redeem-service'

const PageContent = styled('div')(({ theme }) => ({
  marginBottom: '3rem',
}));


const RedeemPage = () => {

  const navigate = useNavigate();
  const [redeems, setRedeems] = useState({
    data: [],
    cursor: {
      afterCursor: '',
      beforeCursor: '',
    },
  });

  const [tableSize, setTableSize] = useState({
    default: 10,
    list: [5, 10, 25],
  });

  const [currentCursor, setCurrentCursor] = useState({
    name: '',
    value: '',
  });

  const [redeemSearchedValues, setRedeemSearchedValues] = useState<any>({
    userPhoneNumber: '',
  })


  const pageSizeHandler = (pageSize: number) => {
    setTableSize({
      ...tableSize,
      default: pageSize,
    });
    getRedeemsQuery.refetch();
  };

  const changePageHandler = (changePage: string) => {
    const { cursor } = redeems || {};
    const { afterCursor, beforeCursor } = cursor || {};
    if (changePage === 'go_back' && beforeCursor !== '') {
      setCurrentCursor({
        name: 'previous',
        value: beforeCursor,
      });
    } else {
      if (afterCursor !== '') {
        setCurrentCursor({
          name: 'next',
          value: afterCursor,
        });
      }
    }
    getRedeemsQuery.refetch();
  };

  const redeemSearchHandler = (feild: string, value: any) => {
    switch(feild){
      case 'user_phoneNumber':
        setRedeemSearchedValues({ ...redeemSearchedValues, userPhoneNumber: value}) 
        break;
      default:
        break;
    }
  }

  const getRedeemsQuery = useQuery(
    ['redeems', tableSize.default, redeemSearchedValues, currentCursor.value],
    async () => getRedeems({ 
      limit: tableSize.default,
      redeemSearchedParams: redeemSearchedValues,
      afterCursor: currentCursor.name === 'next' ? currentCursor.value : '',
      beforeCursor: currentCursor.name === 'previuous' ? currentCursor.value : '', }),
    {
      onSuccess: (data) => {
        setRedeems(data);
      },
      onError: (err: AxiosError) => errorHandler(err, navigate),
      refetchOnWindowFocus: true,
    },
  );

  const redeemClickHandler = (row: any) => {
    navigate(`/redeem/${row?.uuid}`)
  }


  return (
    <>
      <PageContent>
        <Title title="Redeem" />
      </PageContent>
      <DataTable
        data={redeems?.data.length ? redeems : []}
        columns={columns}
        buttonText={''}
        pageSizeChangeHandler={(pageSize: number) => pageSizeHandler(pageSize)}
        changePageHandler={changePageHandler}
        tableSize={tableSize}
        inputChangeHandler={redeemSearchHandler}
        showUserPhoneFilter={true}
        rowClickHandler={redeemClickHandler}
      />

    </>
  )
}

export default RedeemPage;