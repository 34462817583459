import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Title from '../../components/Title/index';
import DataTable from '../../components/DataTable/index';
import { columns } from './table-columns';
import { useMutation, useQuery } from 'react-query';
import { getOutboxes, resendOneOutboxEvent } from '../../services/app/outbox-service';
import ConfirmationModal from '../../components/ConfirmationModal/index'
import { AxiosError } from 'axios';
import { errorHandler } from '../../utils/network/error-handler';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';


const PageContent = styled('div')(({ theme }) => ({
  marginBottom: '3rem',
}));

const Outbox = () => {

  const navigate = useNavigate();
  const [outbox, setOutBox] = useState({
    data: [],
    cursor: {
      afterCursor: '',
      beforeCursor: '',
    },
  });

  const [tableSize, setTableSize] = useState({
    default: 10,
    list: [5, 10, 25],
  });

  const [currentCursor, setCurrentCursor] = useState({
    name: '',
    value: '',
  });

  const [outboxSearchedValues, setOutboxSearchedValues] = useState<any>({
    status: '',
    operationUuid: '',
    eventName: '',
    entityName: '',
    entityId: '',
  })

  const [ outboxStatus ] = useState<any>({
    status: [
      'All', 'Sent', 'Created'
    ]
  })
  const [ showLoading, setShowLoading ] = useState(false)
  const [ openConfirmationModal, setOpenConfirmationModal ] = useState(false)
  const [ selectedResendMessageUuid, setSelectedResendMessageUuid ] = useState(false)


  const pageSizeHandler = (pageSize: number) => {
    setTableSize({
      ...tableSize,
      default: pageSize,
    });
    getOutboxesQuery.refetch();
  };

  const changePageHandler = (changePage: string) => {
    const { cursor } = outbox || {};
    const { afterCursor, beforeCursor } = cursor || {};
    if (changePage === 'go_back' && beforeCursor !== '') {
      setCurrentCursor({
        name: 'previous',
        value: beforeCursor,
      });
    } else {
      if (afterCursor !== '') {
        setCurrentCursor({
          name: 'next',
          value: afterCursor,
        });
      }
    }
    getOutboxesQuery.refetch();
  };

  const getOutboxesQuery = useQuery(
    ['outbox', tableSize.default, outboxSearchedValues, currentCursor.value],
    async () => getOutboxes({ 
      limit: tableSize.default,
      outboxSearchedParams: outboxSearchedValues,
      afterCursor: currentCursor.name === 'next' ? currentCursor.value : '',
      beforeCursor: currentCursor.name === 'previuous' ? currentCursor.value : '', }),
    {
      onSuccess: (data) => {
        setOutBox(data);
      },
      onError: (err: AxiosError) => errorHandler(err, navigate),
      refetchOnWindowFocus: true,
    },
  );

  const showNotification = (message: string ) => {
    toast.success(`${message}`, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
    return 
  }

  const resendOneOutBoxQuery = useMutation((data: any) => resendOneOutboxEvent(data), {
    onSuccess: (data) => {
      if(data?.id){
        showNotification('Outbox Event Re-sended Successfully');
        setShowLoading(false)
        setOpenConfirmationModal(!openConfirmationModal)
        setSelectedResendMessageUuid(false)
        getOutboxesQuery.refetch()
      }
    },
    onError: (err: AxiosError) => errorHandler(err, navigate),
  })

  const outboxSearchHandler = (feild: string, value: any) => {

    switch(feild) {
      case 'operationUuid':
        setOutboxSearchedValues({...outboxSearchedValues, operationUuid: value})
        break;
      case 'eventName':
        setOutboxSearchedValues({...outboxSearchedValues, eventName: value})
        break;
      case 'outbox_status':
        setOutboxSearchedValues({...outboxSearchedValues, status: value.toLowerCase()})
        break;
      case 'entityName':
        setOutboxSearchedValues({...outboxSearchedValues, entityName: value.toLowerCase()})
        break;
      case 'entityId':
        setOutboxSearchedValues({...outboxSearchedValues, entityId: value.toLowerCase()})
        break;
      default: 
        break;
    }
  }

  const resendOutBoxEvent = (row:any) => {
    setOpenConfirmationModal(!openConfirmationModal)
    setSelectedResendMessageUuid(row?.operationUuid)
  }

  const closeConfirmationModal = () => {
    setOpenConfirmationModal(!openConfirmationModal)
  }

  const reSendMessagesHandler = () => {
    setShowLoading(true);
    resendOneOutBoxQuery.mutate({operationUuid: selectedResendMessageUuid})
  }

  return (
    <>
      <PageContent>
        <Title title="OutBox" />
      </PageContent>
      <DataTable
        data={outbox?.data.length ? outbox : []}
        columns={columns}
        buttonText={''}
        pageSizeChangeHandler={(pageSize: number) => pageSizeHandler(pageSize)}
        changePageHandler={changePageHandler}
        tableSize={tableSize}
        inputChangeHandler={outboxSearchHandler}
        orderValues={outboxStatus}
        showOutBoxFilters={true}
        editRecordHandler={resendOutBoxEvent}
      />
      <ConfirmationModal
        title={'Re-send OutBox Event'}
        text={'Are You Sure to Re-Send OutBox Event'}
        openModal={openConfirmationModal}
        closeModal={closeConfirmationModal}
        submitForm={reSendMessagesHandler}
      />
      <ToastContainer />

    </>
  )
}

export default Outbox;