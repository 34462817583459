/* eslint-disable @typescript-eslint/no-unused-expressions */
import network from '../../utils/network';

interface getMessagesParams {
  limit?: number;
  messagesSearchedParams?: any;
  afterCursor?: string;
  beforeCursor?: string;
}

export const getMessages = async ({ limit, messagesSearchedParams, afterCursor, beforeCursor }: getMessagesParams) => {
  let params: { [key: string]: any } = {};
  params.limit = limit;
  messagesSearchedParams.userPhoneNumber && (params.userPhoneNumber = messagesSearchedParams.userPhoneNumber);
  messagesSearchedParams.userEmail && (params.userEmail = messagesSearchedParams.userEmail);

  afterCursor ? (params.afterCursor = afterCursor) : '';
  beforeCursor ? (params.beforeCursor = beforeCursor) : '';

  const response = await network.get({
    path: `/messages`,
    options: {
      params,
    },
  });
  return response?.data;
};

export const getMessageInfo = async (uuid: string) => {
  const response = await network.get({
    path: `/messages/${uuid}`,
  });

  return response?.data;
};

export const resendAllMessages = async (data: any) => {
  const response = await network.post('/messages/resend-all', data);

  return response?.data;
};

export const resendOneMessages = async (data: any) => {
  const response = await network.post('/messages/resend-one', data);

  return response?.data;
};
