import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Title from '../../components/Title/index';
import DataTable from '../../components/DataTable/index';
import { columns } from './table-columns';
import { getListings, cancelListing } from '../../services/app/listing-service';
import { useQuery, useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { errorHandler } from '../../utils/network/error-handler';
import { useNavigate } from 'react-router-dom';


const PageContent = styled('div')(({ theme }) => ({
  marginBottom: '3rem',
}));


const ListingPage = () => {
  const navigate = useNavigate();

  const [listing, setListing] = useState({
    data: [],
    cursor: {
      afterCursor: '',
      beforeCursor: '',
    },
  });

  const [tableSize, setTableSize] = useState({
    default: 10,
    list: [5, 10, 25],
  });

  const [currentCursor, setCurrentCursor] = useState({
    name: '',
    value: '',
  });

  const pageSizeHandler = (pageSize: number) => {
    setTableSize({
      ...tableSize,
      default: pageSize,
    });
    getListingQuery.refetch();
  };

  const changePageHandler = (changePage: string) => {
    const { cursor } = listing || {};
    const { afterCursor, beforeCursor } = cursor || {};
    if (changePage === 'go_back' && beforeCursor !== '') {
      setCurrentCursor({
        name: 'previous',
        value: beforeCursor,
      });
    } else {
      if (afterCursor !== '') {
        setCurrentCursor({
          name: 'next',
          value: afterCursor,
        });
      }
    }
    getListingQuery.refetch();
  };

  const getListingQuery = useQuery(
    ['listings', tableSize.default, currentCursor.value],
    async () => getListings({ 
      limit: tableSize.default,
      afterCursor: currentCursor.name === 'next' ? currentCursor.value : '',
      beforeCursor: currentCursor.name === 'previuous' ? currentCursor.value : '', }),
    {
      onSuccess: (data) => {
        setListing(data);
      },
      onError: (err: AxiosError) => errorHandler(err, navigate),
      refetchOnWindowFocus: true,
    },
  );

  const cancelListingQuery = useMutation((data: any) => cancelListing(data), {
    onSuccess: (response: any) => {
      getListingQuery.refetch()
    },
    onError: (err: AxiosError) => errorHandler(err, navigate)
  })

  const cancelListingHandler = (listing: any) => {
    cancelListingQuery.mutate({listingUuid: listing?.uuid, userUuid: listing?.user?.uuid})
  }

  return (
    <>
      <PageContent>
        <Title title="Listing" />
      </PageContent>
      <DataTable
        data={listing?.data.length ? listing : []}
        columns={columns}
        buttonText={''}
        pageSizeChangeHandler={(pageSize: number) => pageSizeHandler(pageSize)}
        changePageHandler={changePageHandler}
        tableSize={tableSize}
        editRecordHandler={cancelListingHandler}
      />
    </>
  )

}

export default ListingPage