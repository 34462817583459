/* eslint-disable @typescript-eslint/no-unused-expressions */
import network from '../../utils/network';

interface getOutBoxParams {
  limit?: number;
  ticketProviderId?: string;
  outboxSearchedParams?: any;
  afterCursor?: string;
  beforeCursor?: string;
}

export const getOutboxes = async ({
  limit,
  ticketProviderId,
  outboxSearchedParams,
  afterCursor,
  beforeCursor,
}: getOutBoxParams) => {
  let params: { [key: string]: any } = {};
  params.limit = limit;
  outboxSearchedParams.status && outboxSearchedParams.status !== 'all' && (params.status = outboxSearchedParams.status);
  outboxSearchedParams.operationUuid && (params.operationUuid = outboxSearchedParams.operationUuid);
  outboxSearchedParams.eventName && (params.eventName = outboxSearchedParams.eventName);
  outboxSearchedParams.entityName && (params.entityName = outboxSearchedParams.entityName);
  outboxSearchedParams.entityId && (params.entityId = outboxSearchedParams.entityId);

  afterCursor ? (params.afterCursor = afterCursor) : '';
  beforeCursor ? (params.beforeCursor = beforeCursor) : '';

  ticketProviderId && (params.ticketProviderId = ticketProviderId);

  const response = await network.get({
    path: `/outbox`,
    options: {
      params,
    },
  });
  return response?.data;
};

export const resendOneOutboxEvent = async (data: any) => {
  const { operationUuid } = data;
  const response = await network.post(`/outbox/${operationUuid}/resend`);

  return response?.data;
};
