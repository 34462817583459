import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Title from '../../components/Title/index';
import { useQuery, useMutation } from 'react-query';
import { getSettings, updateSettings } from '../../services/app/settings-service';
import { AxiosError } from 'axios';
import { errorHandler } from '../../utils/network/error-handler';
import DataTable from '../../components/DataTable';
import { columns } from './table-columns'
import EditSettingsModal from './EditSettingsModal'

interface SettingsPageProps {}

const PageContent = styled('div')(({ theme }) => ({
  marginBottom: '3rem',
}));


const SettingsPage: FC<SettingsPageProps> = () => {
  const navigate = useNavigate()
  const [ settings, setSettings ] = useState<any>({
    data: [],
    cursor: {
      afterCursor: '',
      beforeCursor: '',
    },
  })
  const [tableSize, setTableSize] = useState({
    default: 10,
    list: [5, 10, 25],
  });

  const [currentCursor, setCurrentCursor] = useState({
    name: '',
    value: '',
  });

  const [ openModal, setOpenModal ] = useState(false)
  const [ modalValues, setModalValues ] = useState<any>({})


  
  const getSettingsQuery = useQuery(
    ['settings', tableSize.default, currentCursor.value],
    async () => getSettings({ 
      limit: tableSize.default,
      afterCursor: currentCursor.name === 'next' ? currentCursor.value : '',
      beforeCursor: currentCursor.name === 'previuous' ? currentCursor.value : '', }),
    {
      onSuccess: (data) => {
        setSettings(data);
      },
      onError: (err: AxiosError) => errorHandler(err, navigate),
      refetchOnWindowFocus: true,
    },
  );

  const updateSettingsQuery = useMutation((data: any) => updateSettings(data), {
    onSuccess: (data) => {
      setModalValues({});
      setOpenModal(false)
      getSettingsQuery.refetch();
    },
    onError: (err: AxiosError) => errorHandler(err, navigate)

  })

  const pageSizeHandler = (pageSize: number) => {
    setTableSize({
      ...tableSize,
      default: pageSize,
    });
    getSettingsQuery.refetch();
  };

  const changePageHandler = (changePage: string) => {
    const { cursor } = settings || {};
    const { afterCursor, beforeCursor } = cursor || {};
    if (changePage === 'go_back' && beforeCursor !== '') {
      setCurrentCursor({
        name: 'previous',
        value: beforeCursor,
      });
    } else {
      if (afterCursor !== '') {
        setCurrentCursor({
          name: 'next',
          value: afterCursor,
        });
      }
    }
    getSettingsQuery.refetch();
  }

  const editSettingsHandler = (record: any) => {
    
    const foundedRecord = settings?.data?.find((setting: any) => setting.id === record.id);
    setModalValues(foundedRecord)
    setOpenModal(true);
  }

  const closeModal = () => {
    setModalValues({})
    setOpenModal(false);
  }

  const modalValuesHandler = (field: string, value: string) => {
    if(field === 'value'){
      setModalValues({
        ...modalValues,
        value
      })
    }
  }

  const updateModalHandler = () => {
    const { key, value } = modalValues;
    updateSettingsQuery.mutate({key, value})
  }

  return (
    <>
      <PageContent>
        <Title title='Settings' />
      </PageContent>

      <DataTable
        data={settings?.data.length ? settings : []}
        columns={columns}
        buttonText={''}
        pageSizeChangeHandler={(pageSize: number) => pageSizeHandler(pageSize)}
        changePageHandler={changePageHandler}
        tableSize={tableSize}
        editRecordHandler={editSettingsHandler}
      />

      <EditSettingsModal
        title="Edit Setting"
        openModal={openModal}
        closeModal={closeModal}
        submitForm={updateModalHandler}
        inputValueHandler={(field: string, value: string) => modalValuesHandler(field, value)}
        modalValues={modalValues}
      />
    </>
  )
}

export default SettingsPage;